import File from "./File";

export default class Document {
  evaluationDocumentId?: number;
  evaluationId?: number | null;
  documentName?: string | null;
  description?: string | null;
  dateRequested?: Date | null;
  dateReceived?: Date | null;
  isOptional?: Boolean | null;
  fileId?: number | null;
  file?: File | null;

  public constructor(params: Document = {} as Document) {
    let {
      evaluationDocumentId = 0,
      evaluationId = 0,
      documentName = "",
      description = "",
      dateRequested = null,
      dateReceived = null,
      isOptional = false,
      fileId = null,
      file = null,
    } = params;
    this.evaluationDocumentId = evaluationDocumentId;
    this.evaluationId = evaluationId;
    this.documentName = documentName;
    this.description = description;
    this.dateRequested = dateRequested;
    this.dateReceived = dateReceived;
    this.isOptional = isOptional;
    this.fileId = fileId;
    this.file = file && new File(file);
  }

  get required() {
    return !Boolean(this.isOptional);
  }

  set required(newVal) {
    this.isOptional = !Boolean(newVal);
  }

  get filename() {
    return this.file?.fileName;
  }
}
