<template>
  <v-dialog v-model="dialog" width="800" max-height="700">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip top v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn fab icon large @click="dialog = true" color="primary"
              ><v-icon>mdi-folder-open-outline</v-icon></v-btn
            >
          </span>
        </template>
        <span> Documents</span>
      </v-tooltip>
    </template>

    <v-card v-if="dialog">
      <v-card-title>
        Documents
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn> </v-card-title
      ><v-card-text>
        <documents-table :documents="documents"></documents-table>
        <v-row class="justify-center my-4">
          <document-modal
            :newDocument="true"
          ></document-modal></v-row></v-card-text
    ></v-card>
  </v-dialog>
</template>
<script>
import DocumentsTable from "./documents-table.vue";
import DocumentModal from "./document-modal.vue";
export default {
  components: {
    DocumentsTable,
    DocumentModal,
  },
  data() {
    return { dialog: false };
  },
  computed: {
    evaluation() {
      return this.$store.state.evaluationsModule.selectedEval;
    },
    documents() {
      return this.evaluation?.documents || [];
    },
  },
};
</script>
