<template>
  <v-card outlined min-width="150" min-height="250" class="primary--text">
    <v-card-title>{{ school.schoolName }}</v-card-title>
    <v-card-text>
      <div class="mb-1">
        Legacy Id:
        <span class="font-weight-bold">{{ school.legacySchoolId }}</span>
      </div>
      <div class="my-1" v-if="school.summerCamp">Summer Camp</div>
      <v-divider class="my-3"></v-divider>
      <div class="my-1">
        Address:
        <span class="font-weight-bold"
          >{{ school.streetAddress }} {{ school.city }} {{ school.state }}
          {{ school.zipCode }}</span
        >
      </div>
      <div class="my-1">
        Phone:
        <span class="font-weight-bold">{{ school.phone }}</span>
        <span v-if="school.phoneExtension">
          Ext:
          <span class="font-weight-bold">{{ school.phoneExtension }}</span>
        </span>
      </div>
      <div class="my-1" v-if="school.secondaryPhone">
        Secondary Phone:
        <span class="font-weight-bold">{{ school.secondaryPhone }}</span>
        <span v-if="school.secondaryPhoneExtension">
          Ext:
          <span class="font-weight-bold">{{
            school.secondaryPhoneExtension
          }}</span>
        </span>
      </div>
      <div class="my-1" v-if="school.emailAddress">
        Email:
        <span class="font-weight-bold">{{ school.emailAddress }}</span>
      </div>
      <div class="my-1" v-if="school.contact">
        Contact:
        <span class="font-weight-bold">{{ school.contact }}</span>
      </div>
      <v-divider role="presentation" class="my-3"></v-divider>
      <div class="my-1">
        School Code:
        <span class="font-weight-bold"
          >{{ school.schoolBoro }} - {{ school.districtId }} -
          {{ school.schoolCode }}</span
        >
      </div>
      <div
        class="my-1"
        v-if="
          school.secondaryDistrictId &&
          school.secondarySchoolBoro &&
          school.secondarySchoolCode
        "
      >
        School Code 2:
        <span class="font-weight-bold"
          >{{ school.secondarySchoolBoro }} - {{ school.secondaryDistrictId }} -
          {{ school.secondarySchoolCode }}</span
        >
      </div>
      <template v-if="subSchool">
        <v-divider class="my-3"></v-divider>
        <div class="my-1">
          Program:
          <span class="font-weight-bold">{{ subSchool.schoolName }}</span>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ["school", "subSchool"],
};
</script>
