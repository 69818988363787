<template>
  <v-data-table
    :headers="headers"
    :items="documents"
    dense
    disable-pagination
    hide-default-footer
  >
    <template v-slot:[`item.dateRequested`]="{ item }">
      <v-tooltip v-if="item.dateRequested" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-check</v-icon>
        </template>
        <span class="font-italic"
          >Requested {{ formatDate(item.dateRequested) }}</span
        >
      </v-tooltip>
    </template>
    <template v-slot:[`item.dateReceived`]="{ item }">
      <v-tooltip v-if="item.dateReceived" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-check</v-icon>
        </template>
        <span class="font-italic"
          >Received {{ formatDate(item.dateReceived) }}</span
        >
      </v-tooltip>
    </template>
    <template v-slot:[`item.required`]="{ item }">
      <v-simple-checkbox v-model="item.required" disabled></v-simple-checkbox>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <document-modal :documentInput="item"></document-modal>
      <delete-document-modal :document="item"></delete-document-modal>
      <upload-file-action
        :title="`${item.documentName}`"
        :fileId="item.fileId"
        @fileUploaded="updateDocumentWithFile(item, $event)"
      ></upload-file-action>
      <preview-file-action
        :fileId="item.fileId"
        :file="item.file"
      ></preview-file-action>
      <download-file-action
        :fileId="item.fileId"
        :filename="item.filename"
      ></download-file-action>
    </template>
  </v-data-table>
</template>
<script>
import { formatDate } from "../../utils/format-utils";
import DocumentModal from "./document-modal.vue";
import DeleteDocumentModal from "./delete-document-modal.vue";
import PreviewFileAction from "../Shared/preview-file-action.vue";
import DownloadFileAction from "../Shared/download-file-action.vue";
import UploadFileAction from "../Shared/upload-file-action.vue";
export default {
  props: ["documents"],
  components: {
    DocumentModal,
    DeleteDocumentModal,
    PreviewFileAction,
    DownloadFileAction,
    UploadFileAction,
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "documentName" },
        { text: "Description", value: "description" },
        {
          text: "Requested",
          value: "dateRequested",
          align: "center",
        },
        {
          text: "Received",
          value: "dateReceived",
          align: "center",
        },
        { text: "Required", value: "required", align: "center" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    evaluationId() {
      return this.$store.state.evaluationsModule.selectedEval?.evaluationId;
    },
  },
  methods: {
    formatDate,
    async updateDocumentWithFile(item, event) {
      let success = await this.$store.dispatch(
        "evaluationsModule/updateDocument",
        { ...item, evaluationId: this.evaluationId, fileId: event.fileId }
      );
      if (!success) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error uploading file");
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
