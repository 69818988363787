import * as api from "../Services/api.js";
import EvaluationDetails from "../../objs/EvaluationDetails";
import Evaluation from "../../objs/Evaluation";
import Assessment from "../../objs/Assessment";
import Mandate from "../../objs/Mandate";
import Note from "../../objs/Note";
import Document from "../../objs/Document";

export const state = {
  evaluations: [],  // EvaluationDetails model with subset fields
  selectedEval: null, // Evaluation model with all fields
  filters: {
    clientFilter: "",
    schoolFilter: "",
    statusFilter: "",
    activeFilter: true,
    typeFilter: "",
    referralFilter: "",
    zipCodeFilter: "",
    districtFilter: "",
    districtAdminFilter: "",
  },
};

export const mutations = {
  SET_EVALUATIONS(state, payload) {
    state.evaluations = payload.map((item) => new EvaluationDetails(item));
  },
  ADD_EVALUATION(state, payload) {
    state.evaluations.push(new EvaluationDetails(payload));
  },
  UPDATE_EVALUATION(state, payload) {
    state.evaluations = state.evaluations.map((item) => {
      return item.evaluationId == payload.evaluationId
        ? new EvaluationDetails(payload)
        : item;
    });
  },
  SET_SELECTED_EVAL(state, payload) {
    state.selectedEval = new Evaluation(payload);
  },
  UPDATE_SELECTED_EVAL(state, payload) {
    state.selectedEval = {
      ...state.selectedEval,
      ...payload,
    };
  },
  ADD_ASSESSMENT(state, payload) {
    state.selectedEval.assessments.push(new Assessment(payload));
  },
  UPDATE_ASSESSMENT(state, payload) {
    state.selectedEval.assessments = state.selectedEval.assessments.map(
      (item) => {
        return item.assessmentId == payload.assessmentId
          ? new Assessment(payload)
          : item;
      }
    );
  },
  DELETE_ASSESSMENT(state, assessmentId) {
    state.selectedEval.assessments = state.selectedEval.assessments.filter(
      (item) => item.assessmentId != assessmentId
    );
  },
  ADD_MANDATE(state, payload) {
    state.selectedEval.approvedMandates.push(new Mandate(payload));
  },
  UPDATE_MANDATE(state, payload) {
    state.selectedEval.approvedMandates =
      state.selectedEval.approvedMandates.map((item) => {
        return item.mandateId == payload.mandateId
          ? new Mandate(payload)
          : item;
      });
  },
  DELETE_MANDATE(state, mandateId) {
    state.selectedEval.approvedMandates =
      state.selectedEval.approvedMandates.filter(
        (item) => item.mandateId != mandateId
      );
  },
  ADD_NOTE(state, payload) {
    state.selectedEval.notes.push(new Note(payload));
  },
  UPDATE_NOTE(state, payload) {
    state.selectedEval.notes = state.selectedEval.notes.map((item) => {
      return item.evaluationNoteId == payload.evaluationNoteId
        ? new Note(payload)
        : item;
    });
  },
  DELETE_NOTE(state, noteId) {
    state.selectedEval.notes = state.selectedEval.notes.filter(
      (item) => item.evaluationNoteId != noteId
    );
  },
  ADD_DOCUMENT(state, payload) {
    state.selectedEval.documents.push(new Document(payload));
  },
  UPDATE_DOCUMENT(state, payload) {
    state.selectedEval.documents = state.selectedEval.documents.map((item) => {
      return item.evaluationDocumentId == payload.evaluationDocumentId
        ? new Document(payload)
        : item;
    });
  },
  DELETE_DOCUMENT(state, documentId) {
    state.selectedEval.documents = state.selectedEval.documents.filter(
      (item) => item.evaluationDocumentId != documentId
    );
  },
  SET_FILTERS(state, payload) {
    state.filters[payload.filterName] = payload.filterValue;
  },
  CLEAR_FILTERS(state) {
    state.filters = {
      clientFilter: "",
      schoolFilter: "",
      statusFilter: "",
      activeFilter: true,
      typeFilter: "",
      referralFilter: [],
      zipCodeFilter: "",
      districtFilter: "",
      districtAdminFilter: "",
    };
  },
};

export const getters = {
  getClientOptions: (state) => {
    return state.evaluations
      .map((item) => item.client)
      .sort((a, b) => a.lastName.localeCompare(b.lastName));
  },
  getSchoolOptions: (state, getters) => {
    return getters.getClientOptions
      .reduce((acc, item) => {
        item.currentSchool && acc.push(item.currentSchool);
        item.currentSubSchool && acc.push(item.currentSubSchool);
        return acc;
      }, [])
      .sort((a, b) => a.schoolName.localeCompare(b.schoolName));
  },
  getActiveClientIds: (state) => {
    return state.evaluations
      .filter((item) => item.completedAt == null)
      .map((item) => item.client?.clientId);
  },
};

export const actions = {
  updateSelectedEval({ commit }, data) {
    commit("UPDATE_SELECTED_EVAL", data);
  },
  async getEvaluationsBySchool({ commit, dispatch, rootState }) {
    let schoolId = rootState.sharedModule.schoolId;
    if (!schoolId) {
      schoolId = await dispatch("sharedModule/getSchoolId", null, {
        root: true,
      });
    }

    return await api
      .getQuery("/evaluations/findEvaluationsBySchool", { schoolId: schoolId })
      .then((response) => {
        if (response.success) {
          commit("SET_EVALUATIONS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async getAllEvaluations({ commit }) {
    return await api
      .get("/evaluations/findAllEvaluations")
      .then((response) => {
        if (response.success) {
          commit("SET_EVALUATIONS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async loadEvaluation({ commit }, data) {
    return await api
      .getQuery("/evaluations/loadEvaluation", {
        evaluationId: data.evaluationId,
      })
      .then((response) => {
        if (response.success) {
          commit("SET_SELECTED_EVAL", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async createEvaluation({ commit, dispatch }, data) {
    return await api
      .post("/evaluations/createEvaluation", data)
      .then((response) => {
        if (response.success) {
          commit("ADD_EVALUATION", response.data);
          dispatch("loadEvaluation", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async saveSelectedEvaluation({ commit, state, dispatch }) {
    return await api
      .post("/evaluations/updateEvaluation", state.selectedEval)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_EVALUATION", response.data);
          dispatch("loadEvaluation", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async createAssessment({ commit, dispatch }, data) {
    return await api
      .post("/evaluations/createAssessment", data)
      .then((response) => {
        if (response.success) {
          commit("ADD_ASSESSMENT", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async updateAssessment({ commit, dispatch }, data) {
    return await api
      .post("/evaluations/updateAssessment", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_ASSESSMENT", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async deleteAssessment({ commit, dispatch }, data) {
    return await api
      .postQuery("/evaluations/deleteAssessment", data)
      .then((response) => {
        if (response.success) {
          commit("DELETE_ASSESSMENT", data.assessmentId);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async createMandate({ commit, dispatch }, data) {
    return await api
      .postQueryWithBody(
        "/evaluations/createMandate",
        { evaluationId: data.evaluationId },
        data
      )
      .then((response) => {
        if (response.success) {
          commit("ADD_MANDATE", response.data);
        } else {
          commit(
            "uxModule/setSnackbarMsg",
            `Error saving mandate: ${response.message}`,
            {
              root: true,
            }
          );
          commit("uxModule/setShowSnackbar", true, { root: true });
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async updateMandate({ commit, dispatch }, data) {
    return await api
      .post("/evaluations/updateMandate", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_MANDATE", response.data);
        } else {
          commit(
            "uxModule/setSnackbarMsg",
            `Error saving mandate: ${response.message}`,
            {
              root: true,
            }
          );
          commit("uxModule/setShowSnackbar", true, { root: true });
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async deleteMandate({ commit, dispatch }, data) {
    return await api
      .postQuery("/evaluations/deleteMandate", data)
      .then((response) => {
        if (response.success) {
          commit("DELETE_MANDATE", data.mandateId);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async saveNote({ commit, dispatch, rootState, rootGetters }, data) {
    let user = rootState.securityModule.userProfile;
    let isSchoolUser = rootGetters["securityModule/hasUserRole"](["School"]);

    return await api
      .post("/evaluations/createNote", {
        ...data,
        userId: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        sendNotification: isSchoolUser,
      })
      .then((response) => {
        if (response.success) {
          commit("ADD_NOTE", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async updateNote({ commit, dispatch }, data) {
    return await api
      .post("/evaluations/updateNote", {
        ...data,
      })
      .then((response) => {
        if (response.success) {
          commit("UPDATE_NOTE", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async deleteNote({ commit, dispatch }, data) {
    return await api
      .postQuery("/evaluations/deleteNote", data)
      .then((response) => {
        if (response.success) {
          commit("DELETE_NOTE", data.noteId);
          return "";
        }

        return response.message;
      })
      .catch((error) => {
        return false;
      });
  },
  async createDocument({ commit, dispatch }, data) {
    return await api
      .post("/evaluations/createDocument", data)
      .then((response) => {
        if (response.success) {
          commit("ADD_DOCUMENT", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async updateDocument({ commit, dispatch }, data) {
    return await api
      .post("/evaluations/updateDocument", data)
      .then((response) => {
        if (response.success) {
          commit("UPDATE_DOCUMENT", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async deleteDocument({ commit, dispatch }, data) {
    return await api
      .postQuery("/evaluations/deleteDocument", data)
      .then((response) => {
        if (response.success) {
          commit("DELETE_DOCUMENT", data.documentId);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  setFilters({ commit }, data) {
    commit("SET_FILTERS", data);
  },
  clearFilters({ commit }) {
    commit("CLEAR_FILTERS");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
