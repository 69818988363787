<template>
  <div>
    <v-toolbar flat dense>
      <v-spacer></v-spacer>
      <new-evaluation-modal
        v-if="!noEdit"
        :loading="loading"
      ></new-evaluation-modal>
      <import-client-modal v-if="!noEdit"></import-client-modal>
    </v-toolbar>
    <v-toolbar flat>
      <v-subheader>Filter By</v-subheader>
      <v-col cols="12" sm="3">
        <v-autocomplete
          v-model="clientFilter"
          :items="clients"
          clearable
          label="Client"
          :item-text="
            (item) =>
              `${item.lastName}, ${item.firstName} (${formatDate(
                item.dateOfBirth
              )})`
          "
          item-value="clientId"
          hide-details
          dense
          outlined
          class="mx-1"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3">
        <v-autocomplete
          v-model="schoolFilter"
          :items="schools"
          clearable
          label="School"
          item-text="schoolName"
          item-value="schoolId"
          hide-details
          dense
          outlined
          class="mx-1"
          :disabled="noEdit"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2">
        <v-select
          v-model="statusFilter"
          :items="statuses"
          clearable
          label="Status"
          hide-details
          dense
          outlined
          class="mx-1"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="1">
        <v-switch v-model="activeFilter" x-small hide-details class="mx-1">
          <template v-slot:label>
            <span class="text-body-2">Active Cases only</span></template
          >
        </v-switch>
      </v-col>
      <v-col cols="12" sm="3">
        <filters-modal
          @clearAll="clearAllFilters"
          @apply="applyMoreFilters"
        ></filters-modal>
        <v-btn text x-small @click="clearAllFilters">Clear Filters</v-btn>
      </v-col>
    </v-toolbar>
    <v-spacer class="my-3"></v-spacer>
    <evaluations-table
      :evaluations="filteredEvals"
      :noEdit="noEdit"
    ></evaluations-table>
  </div>
</template>
<script>
import { EvaluationStatus } from "../../objs/EvaluationStatus";
import { formatDate } from "../../utils/format-utils";
import NewEvaluationModal from "./new-evaluation-modal.vue";
import ImportClientModal from "./import-client-modal.vue";
import EvaluationsTable from "./evaluations-table.vue";
import FiltersModal from "./filters-modal.vue";
export default {
  components: {
    NewEvaluationModal,
    ImportClientModal,
    EvaluationsTable,
    FiltersModal,
  },
  async mounted() {
    this.loading = true;
    if (this.$store.state.optionsModule.clients.length == 0)
      await this.$store.dispatch("optionsModule/getAllClients");
    this.loading = false;

    if (this.isSchoolUser) {
      this.schoolFilter = this.$store.state.sharedModule.schoolId;
    }
  },
  data() {
    return {
      loading: false,
      statuses: Object.values(EvaluationStatus),
    };
  },
  computed: {
    isSchoolUser() {
      const schoolRoles = ["School"];
      return this.$store.getters["securityModule/hasUserRole"](schoolRoles);
    },
    noEdit() {
      return this.isSchoolUser;
    },
    evaluations() {
      return this.$store.state.evaluationsModule.evaluations || [];
    },
    clientFilter: {
      get() {
        return this.$store.state.evaluationsModule.filters.clientFilter || "";
      },
      set(value) {
        this.$store.dispatch("evaluationsModule/setFilters", {
          filterName: "clientFilter",
          filterValue: value,
        });
      },
    },
    schoolFilter: {
      get() {
        return this.$store.state.evaluationsModule.filters.schoolFilter || "";
      },
      set(value) {
        this.$store.dispatch("evaluationsModule/setFilters", {
          filterName: "schoolFilter",
          filterValue: value,
        });
      },
    },
    statusFilter: {
      get() {
        return this.$store.state.evaluationsModule.filters.statusFilter || "";
      },
      set(value) {
        this.$store.dispatch("evaluationsModule/setFilters", {
          filterName: "statusFilter",
          filterValue: value,
        });
      },
    },
    activeFilter: {
      get() {
        return this.$store.state.evaluationsModule.filters.activeFilter;
      },
      set(value) {
        this.$store.dispatch("evaluationsModule/setFilters", {
          filterName: "activeFilter",
          filterValue: value,
        });
      },
    },
    typeFilter: {
      get() {
        return this.$store.state.evaluationsModule.filters.typeFilter || "";
      },
      set(value) {
        this.$store.dispatch("evaluationsModule/setFilters", {
          filterName: "typeFilter",
          filterValue: value,
        });
      },
    },
    referralFilter: {
      get() {
        return this.$store.state.evaluationsModule.filters.referralFilter || [];
      },
      set(value) {
        this.$store.dispatch("evaluationsModule/setFilters", {
          filterName: "referralFilter",
          filterValue: value,
        });
      },
    },
    zipCodeFilter: {
      get() {
        return this.$store.state.evaluationsModule.filters.zipCodeFilter || "";
      },
      set(value) {
        this.$store.dispatch("evaluationsModule/setFilters", {
          filterName: "zipCodeFilter",
          filterValue: value,
        });
      },
    },
    districtFilter: {
      get() {
        return this.$store.state.evaluationsModule.filters.districtFilter || "";
      },
      set(value) {
        this.$store.dispatch("evaluationsModule/setFilters", {
          filterName: "districtFilter",
          filterValue: value,
        });
      },
    },
    districtAdminFilter: {
      get() {
        return (
          this.$store.state.evaluationsModule.filters.districtAdminFilter || ""
        );
      },
      set(value) {
        this.$store.dispatch("evaluationsModule/setFilters", {
          filterName: "districtAdminFilter",
          filterValue: value,
        });
      },
    },
    filteredEvals() {
      let evals = [...this.evaluations];
      if (this.clientFilter) {
        evals = evals.filter((t) => t.clientId == this.clientFilter);
      }
      if (this.schoolFilter) {
        evals = evals.filter(
          (t) =>
            t.schoolId == this.schoolFilter ||
            t.subSchoolId == this.schoolFilter
        );
      }
      if (this.statusFilter) {
        evals = evals.filter((t) => t.status == this.statusFilter);
      }
      if (this.activeFilter === true) {
        evals = evals.filter(
          (t) =>
            ![EvaluationStatus.COMPLETE, EvaluationStatus.TERMINATED].includes(
              t.status
            )
        );
      }
      if (this.activeFilter === false) {
      }
      if (this.typeFilter) {
        evals = evals.filter((t) => t.evaluationType == this.typeFilter);
      }
      if (this.referralFilter?.length > 0) {
        evals = evals.filter((t) =>
          this.referralFilter.includes(t.referralStatus)
        );
      }
      if (this.zipCodeFilter) {
        evals = evals.filter((t) => t.client.zipCode == this.zipCodeFilter);
      }
      if (this.districtFilter) {
        evals = evals.filter((t) => t.client.districtId == this.districtFilter);
      }
      if (this.districtAdminFilter) {
        evals = evals.filter(
          (t) => t.districtAdminId == this.districtAdminFilter
        );
      }
      return evals;
    },
    clients() {
      return this.$store.getters["evaluationsModule/getClientOptions"];
    },
    schools() {
      return this.$store.getters["evaluationsModule/getSchoolOptions"];
    },
  },
  methods: {
    formatDate,
    clearAllFilters() {
      this.$store.dispatch("evaluationsModule/clearFilters");
      this.schoolFilter = this.isSchoolUser
        ? this.$store.state.sharedModule.schoolId
        : "";
    },
    applyMoreFilters(event) {
      this.typeFilter = event.typeFilter;
      this.referralFilter = event.referralFilter;
      this.zipCodeFilter = event.zipCodeFilter;
      this.districtFilter = event.districtFilter;
      this.districtAdminFilter = event.districtAdminFilter;
    },
  },
};
</script>
