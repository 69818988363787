export default class File {
  fileId?: number;
  fileName?: string | null;
  fileExtension?: string | null;

  public constructor(params: File = {} as File) {
    let { fileId = 0, fileName = "", fileExtension = "" } = params;
    this.fileId = fileId;
    this.fileName = fileName;
    this.fileExtension = fileExtension;
  }

  get isPreviewAvailable() {
    return this.fileExtension == "application/pdf";
  }
}
