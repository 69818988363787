<template>
  <router-view></router-view>
</template>
<script>
export default {
  async mounted() {
    this.$store.commit("uxModule/setShowLoader", true);
    if (this.isSchoolUser) {
      await this.$store.dispatch("evaluationsModule/getEvaluationsBySchool");
    } else {
      await this.$store.dispatch("evaluationsModule/getAllEvaluations");
    }
    this.$store.commit("uxModule/setShowLoader", false);
  },
  computed: {
    isSchoolUser() {
      const schoolRoles = ["School"];
      return this.$store.getters["securityModule/hasUserRole"](schoolRoles);
    },
  },
};
</script>
