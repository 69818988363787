<template>
  <v-data-table
    :headers="_headers"
    :items="mandates"
    dense
    disable-pagination
    hide-default-footer
  >
    <template v-slot:[`item.actions`]="{ item }">
      <mandate-modal :mandateInput="item"></mandate-modal>
      <delete-mandate-modal :mandate="item"></delete-mandate-modal>
    </template>
  </v-data-table>
</template>
<script>
import MandateModal from "./mandate-modal.vue";
import DeleteMandateModal from "./delete-mandate-modal.vue";
export default {
  props: ["mandates", "noEdit"],
  components: {
    MandateModal,
    DeleteMandateModal,
  },
  data() {
    return {
      headers: [
        { text: "Program", value: "programName" },
        { text: "Service", value: "serviceName" },
        { text: "Frequency", value: "frequency" },
        {
          text: "Duration (in minutes)",
          value: "durationMinutes",
        },
        { text: "Group Size", value: "groupSize" },
        { text: "Location", value: "location" },
        { text: "Langauge", value: "language" },
        { text: "Start Date", value: "formattedAuthStartDate" },
        { text: "End Date", value: "formattedAuthEndDate" },
        { text: "10 or 12 month?", value: "tenOrTwelveMonth" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          hide: this.noEdit,
        },
      ],
    };
  },
  computed: {
    _headers() {
      return this.headers.filter((x) => !x.hide);
    },
  },
};
</script>
