<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn text x-small v-on="on" @click="showDialog">
              <v-icon x-small>mdi-delete</v-icon>
            </v-btn>
          </span>
        </template>
        <span> Delete </span>
      </v-tooltip>
    </template>

    <v-card class="primary--text">
      <v-card-title>
        Are you sure you want to delete this document?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :dark="!loading"
          :disabled="loading"
          text
          @click="cancel"
          >Cancel</v-btn
        >
        <v-btn
          color="primary"
          :dark="!loading"
          :disabled="loading"
          :loading="loading"
          outlined
          @click="deleteDocument"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["document"],
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },
    async deleteDocument() {
      this.loading = true;
      let success = await this.$store.dispatch(
        "evaluationsModule/deleteDocument",
        {
          documentId: this.document.evaluationDocumentId,
        }
      );
      this.loading = false;

      if (!success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Error deleting document"
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.dialog = false;
    },
  },
};
</script>
