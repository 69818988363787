<template>
  <v-dialog v-model="dialog" width="625">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="newMandate"
        x-small
        rounded
        @click="showDialog"
        color="primary"
        ><v-icon x-small>mdi-plus</v-icon>Add Mandate</v-btn
      >
      <v-tooltip v-else bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn text x-small v-on="on" @click="showDialog">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </span>
        </template>
        <span> Edit mandate </span>
      </v-tooltip>
    </template>

    <v-card class="primary--text pt-4" v-if="dialog">
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="6">
              <validation-provider
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="mandate.programId"
                  :items="programs"
                  :item-text="(item) => `${item.code} - ${item.name}`"
                  item-value="programId"
                  label="Program"
                  dense
                  :error-messages="errors"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6">
              <validation-provider
                name="service"
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  v-model="mandate.serviceId"
                  :items="services"
                  :item-text="(item) => `${item.code} - ${item.name}`"
                  item-value="serviceId"
                  label="Service"
                  dense
                  :error-messages="errors"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col>
              <validation-provider
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="mandate.frequency"
                  :items="frequencies"
                  label="Frequency"
                  dense
                  :error-messages="errors"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col
              ><validation-provider
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="mandate.durationMinutes"
                  label="Duration (in minutes)"
                  :items="durations"
                  dense
                  :error-messages="errors"
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col
              ><validation-provider
                :rules="{ required: true }"
                v-slot="{ errors }"
                ><v-text-field
                  v-model.number="mandate.groupSize"
                  label="Group Size"
                  type="number"
                  min="1"
                  dense
                  :error-messages="errors"
                ></v-text-field></validation-provider
            ></v-col>
          </v-row>
          <v-row>
            <v-col
              ><validation-provider
                :rules="{ required: true }"
                v-slot="{ errors }"
                ><v-select
                  v-model="mandate.locationId"
                  :items="locations"
                  item-text="name"
                  item-value="sessionLocationId"
                  label="Location"
                  dense
                  :error-messages="errors"
                ></v-select></validation-provider
            ></v-col>
            <v-col
              ><validation-provider
                :rules="{ required: true }"
                v-slot="{ errors }"
                ><v-select
                  v-model="mandate.languageId"
                  :items="languages"
                  item-text="name"
                  item-value="languageId"
                  label="Language"
                  dense
                  :error-messages="errors"
                ></v-select></validation-provider
            ></v-col> </v-row
          ><v-row>
            <v-col cols="6">
              <date-picker
                :value="startDate"
                :field="{ label: 'Start Date' }"
                @fieldChange="startDate = $event"
              ></date-picker
            ></v-col>
            <v-col cols="6">
              <date-picker
                :value="endDate"
                :field="{ label: 'End Date' }"
                @fieldChange="endDate = $event"
                :validations="validations.endDate"
              ></date-picker
            ></v-col>
          </v-row>
          <v-row class="ml-4">
            <v-radio-group row v-model="mandate.isTwelveMonth">
              <v-radio :value="true" label="12 month"></v-radio>
              <v-radio
                :value="false"
                label="10 month"
              ></v-radio> </v-radio-group
          ></v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :dark="!loading"
            :disabled="loading"
            text
            @click="clear"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            :dark="!invalid && !loading"
            :disabled="invalid || loading"
            :loading="loading"
            outlined
            @click="save"
            >Save</v-btn
          >
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import Mandate from "../../objs/Mandate";
import { minDate } from "../../plugins/vee-validate/validation";
export default {
  props: ["mandateInput", "newMandate"],
  data() {
    return {
      dialog: false,
      loading: false,
      mandate: this.mandateInput || new Mandate(),
      frequencies: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      durations: [30, 60, 90],
      startDate: this.mandateInput?.authStartDate
        ? new Date(this.mandateInput?.authStartDate)
        : "",
      endDate: this.mandateInput?.authEndDate
        ? new Date(this.mandateInput?.authEndDate)
        : "",
    };
  },
  computed: {
    validations: function () {
      return {
        endDate: {
          minDate: this.startDate,
        },
      };
    },
    programs() {
      return this.$store.state.optionsModule.programs;
    },
    services() {
      return this.$store.state.optionsModule.services;
    },
    languages() {
      return this.$store.state.optionsModule.languages;
    },
    locations() {
      return this.$store.state.optionsModule.locations;
    },
    evaluation() {
      return this.$store.state.evaluationsModule.selectedEval;
    },
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },
    async save() {
      this.loading = true;

      if (this.newMandate) {
        await this.$store.dispatch("evaluationsModule/createMandate", {
          ...this.mandate,
          evaluationId: this.evaluation.evaluationId,
          clientId: this.evaluation.clientId,
          IEPPlanDate: this.evaluation.meetingDate,
          authStartDate: this.startDate,
          authEndDate: this.endDate,
        });
      } else {
        await this.$store.dispatch("evaluationsModule/updateMandate", {
          ...this.mandate,
          evaluationId: this.evaluation.evaluationId,
          clientId: this.evaluation.clientId,
          IEPPlanDate: this.evaluation.meetingDate,
          authStartDate: this.startDate,
          authEndDate: this.endDate,
        });
      }

      this.loading = false;
      this.clear();
    },
    clear() {
      this.$refs.form.reset();
      this.dialog = false;
      this.mandate = this.mandateInput || new Mandate();
      this.startDate = this.mandateInput?.authStartDate
        ? new Date(this.mandateInput?.authStartDate)
        : "";
      this.endDate = this.mandateInput?.authEndDate
        ? new Date(this.mandateInput?.authEndDate)
        : "";
    },
  },
};
</script>
