import axios from "axios";
const urlPrefix = process.env.VUE_APP_OMNI_BASE_URL + "/api";
const securityApiUrlPrefix = process.env.VUE_APP_OMNI_SECURITY_BASE_URL + "/api";

export async function sendFeedbackEmail(data) {
  return axios
    .post(urlPrefix + "/shared/sendFeedbackEmail", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function getUserAccess(data) {
  return axios
    .get(securityApiUrlPrefix + "/identity/getUserAccess", {
      params: {
        clientId: data.clientId,
        userId: data.userId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export function getUser(data) {
  return axios
    .get(securityApiUrlPrefix + "/identity/getUser", {
      params: {
        userId: data,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

export async function get(subURL) {
  return axios
    .get(urlPrefix + subURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function getQuery(subURL, data) {
  return axios
    .get(urlPrefix + subURL, {
      params: data,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function post(subURL, data) {
  return axios
    .post(urlPrefix + subURL, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function postQuery(subURL, data) {
  return axios
    .post(urlPrefix + subURL, null, {
      params: data,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function postQueryWithBody(subURL, query, data) {
  return axios
    .post(urlPrefix + subURL, data, {
      params: query,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function uploadFile(data, userId) {
  let formData = new FormData();
  formData.append("asset", data.file);

  return axios
    .put(urlPrefix + "/files", formData, {
      params: { userId: userId },
      "Content-Type": "multipart/form-data",
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return { success: false, data: error };
    });
}

