<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="newDocument"
        x-small
        rounded
        @click="dialog = true"
        color="primary"
        ><v-icon x-small>mdi-plus</v-icon>Add Document</v-btn
      >
      <v-tooltip v-else bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn text x-small v-on="on" @click="dialog = true">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </span>
        </template>
        <span> Edit details </span>
      </v-tooltip>
    </template>

    <v-card class="primary--text" v-if="dialog">
      <validation-observer ref="form" v-slot="{ invalid }">
        <validation-provider
          name="name"
          :rules="validations.documentName"
          v-slot="{ errors }"
        >
          <v-card-title>
            <v-combobox
              v-model="document.documentName"
              :items="documentNameOptions"
              label="Document Name"
              :error-messages="errors"
            ></v-combobox>
          </v-card-title>
        </validation-provider>
        <v-card-text class="pt-0">
          <v-text-field
            v-model="document.description"
            label="Description"
            dense
          ></v-text-field>
          <v-checkbox v-model="document.required" label="Required"></v-checkbox>
          <date-picker
            :value="dateRequested"
            :field="{ label: 'Date Requested' }"
            @fieldChange="dateRequested = $event"
          ></date-picker
          ><date-picker
            :value="dateReceived"
            :field="{ label: 'Date Received' }"
            @fieldChange="dateReceived = $event"
          ></date-picker>
          <validation-provider>
            <v-file-input
              v-model="selectedFile"
              prepend-icon="mdi-paperclip"
              label="Select a file"
              single-line
            ></v-file-input>
          </validation-provider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :dark="!loading"
            :disabled="loading"
            plain
            @click="clear"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            :dark="!invalid && !loading"
            :disabled="invalid || loading"
            :loading="loading"
            text
            @click="save"
            >Save</v-btn
          >
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import Document from "../../objs/Document";
export default {
  props: ["documentInput", "newDocument"],
  data() {
    return {
      dialog: false,
      loading: false,
      document: this.documentInput || new Document(),
      dateRequested: this.documentInput?.dateRequested
        ? new Date(this.documentInput?.dateRequested)
        : "",
      dateReceived: this.documentInput?.dateReceived
        ? new Date(this.documentInput?.dateReceived)
        : "",
      selectedFile: this.documentInput?.file
        ? new File([], this.documentInput.file.fileName)
        : null,
    };
  },
  computed: {
    validations() {
      return {
        documentName: {
          required: true,
          distinct: [this.otherDocumentNames],
        },
      };
    },
    otherDocumentNames() {
      return this.evaluation?.documents
        .filter(
          (item) =>
            item.evaluationDocumentId != this.document?.evaluationDocumentId
        )
        .map((item) => item.documentName);
    },
    evaluation() {
      return this.$store.state.evaluationsModule.selectedEval;
    },
    documentNameOptions() {
      return [
        {
          header: "Select one or type other option",
        },
        "Birth Certificate",
        "Child Outcome Summary",
        "Consent to Obtain Medical",
        "Consent to Release Information",
        "District IEP",
        "Educational Observation",
        "Electronic Consent",
        "HIPPA Form",
        "HIPPA Receipt",
        "Home Language Survey (HLS)",
        "Intake Form",
        "Medical with immunizations",
        "Preschool Summary Report",
        "Proof of Address",
        "Psychological Observation",
        "Rationale",
        "Rationale Approval",
        "Referral",
        "Signed C1P",
        "STAC-5",
      ];
    },
  },
  methods: {
    async save() {
      this.loading = true;
      let success;

      let file = null;
      if (this.selectedFile) {
        file = await this.$store.dispatch("filesModule/uploadFile", {
          file: this.selectedFile,
        });
        if (!file) {
          this.$store.commit("uxModule/setSnackbarMsg", "Error uploading file");
          this.$store.commit("uxModule/setShowSnackbar", true);
          return;
        }
      }
      if (this.newDocument) {
        success = await this.$store.dispatch(
          "evaluationsModule/createDocument",
          {
            ...this.document,
            evaluationId: this.evaluation.evaluationId,
            dateRequested: this.dateRequested,
            dateReceived: this.dateReceived,
            ...(Boolean(file) && { fileId: file.fileId }),
          }
        );
      } else {
        success = await this.$store.dispatch(
          "evaluationsModule/updateDocument",
          {
            ...this.document,
            evaluationId: this.evaluation.evaluationId,
            dateRequested: this.dateRequested,
            dateReceived: this.dateReceived,
            ...(Boolean(file) && { fileId: file.fileId }),
          }
        );
      }

      this.loading = false;

      if (!success) {
        let message = this.newDocument
          ? "Error creating new document"
          : "Error updating document";
        this.$store.commit("uxModule/setSnackbarMsg", message);
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.clear();
    },
    clear() {
      this.$refs.form.reset();
      this.dialog = false;

      this.document = this.document || new Document();
      this.dateRequested = this.document?.dateRequested
        ? new Date(this.document?.dateRequested)
        : "";
      this.dateReceived = this.document?.dateReceived
        ? new Date(this.document?.dateReceived)
        : "";
    },
  },
};
</script>
