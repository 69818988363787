<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn text x-small v-on="on" @click="dialog = true">
              <v-icon small>mdi-upload</v-icon>
            </v-btn>
          </span>
        </template>
        <span> Upload file </span>
      </v-tooltip>
    </template>

    <v-card class="primary--text" v-if="dialog">
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-card-title> {{ title }} </v-card-title>
        <v-card-text class="pt-0">
          <validation-provider :rules="{ required: true }" v-slot="{ errors }">
            <v-file-input
              v-model="selectedFile"
              prepend-icon="mdi-paperclip"
              label="Select File"
              single-line
              :error-messages="errors"
            ></v-file-input>
          </validation-provider>
        </v-card-text>
        <v-subheader v-show="Boolean(fileId)" class="error--text"
          >* Note: This will overwrite the existing file.</v-subheader
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark plain @click="clear">Cancel</v-btn>
          <v-btn
            color="primary"
            :dark="!invalid"
            :disabled="invalid"
            text
            @click="upload"
            >Save</v-btn
          >
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["title", "fileId"],
  data() {
    return {
      dialog: false,
      selectedFile: null,
    };
  },
  methods: {
    async upload() {
      this.$store.commit("uxModule/setShowLoader", true);
      var file = await this.$store.dispatch("filesModule/uploadFile", {
        file: this.selectedFile,
      });

      this.$store.commit("uxModule/setShowLoader", false);

      if (!file) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error uploading file");
        this.$store.commit("uxModule/setShowSnackbar", true);
      } else {
        this.$emit("fileUploaded", file);
      }
      this.clear();
    },
    clear() {
      this.$refs.form.reset();
      this.selectedFile = null;
      this.dialog = false;
    },
  },
};
</script>
