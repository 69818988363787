<template>
  <v-container>
    <v-row dense>
      <v-col cols="6" md="3">
        <v-checkbox
          v-model="meetingScheduled"
          label="Scheduled On"
          :disabled="noEdit"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" md="4">
        <date-picker
          v-if="meetingScheduled"
          :value="meetingScheduledAt"
          :field="{}"
          @fieldChange="meetingScheduledAt = $event"
          :readonly="noEdit"
          :validations="{ required: meetingScheduled }"
        >
        </date-picker>
      </v-col>
    </v-row>
    <v-row dense v-if="meetingScheduled">
      <v-col cols="6" md="3">
        <v-checkbox
          v-model="parentConfirmed"
          label="Parent Confirmed On"
          :disabled="noEdit"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" md="4">
        <date-picker
          v-if="parentConfirmed"
          :value="parentConfirmedMeetingAt"
          :field="{}"
          @fieldChange="parentConfirmedMeetingAt = $event"
          :readonly="noEdit"
          :validations="{ required: parentConfirmed }"
        >
        </date-picker>
      </v-col>
    </v-row>
    <v-row dense v-if="meetingScheduled">
      <v-col cols="6" md="3">
        <v-checkbox
          v-model="schoolConfirmed"
          label="School Confirmed On"
          :disabled="noEdit"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" md="4">
        <date-picker
          v-if="schoolConfirmed"
          :value="schoolConfirmedMeetingAt"
          :field="{}"
          @fieldChange="schoolConfirmedMeetingAt = $event"
          :readonly="noEdit"
          :validations="{ required: schoolConfirmed }"
        >
        </date-picker>
      </v-col>
    </v-row>
    <v-row dense v-if="meetingScheduled">
      <v-col cols="6" md="3">
        <v-label>Meeting Rep</v-label>
      </v-col>
      <v-col cols="6" md="4">
        <v-autocomplete
          v-model="evaluation.meetingRepId"
          :items="providers"
          :item-text="
            (item) =>
              `${item.firstName} ${item.lastName} - ${item.legacyProviderId}`
          "
          item-value="providerId"
          dense
          :readonly="noEdit"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense v-if="meetingScheduled">
      <v-col cols="6" md="3">
        <v-label>District Administrator</v-label>
      </v-col>
      <v-col cols="6" md="4">
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-autocomplete
                v-model="evaluation.districtAdminId"
                :items="districtAdmins"
                :item-text="(item) => `${item.lastName}, ${item.firstName}`"
                item-value="districtAdminId"
                dense
                :readonly="noEdit"
              ></v-autocomplete
            ></span>
          </template>
          <district-admin-details
            v-if="districtAdmin"
            :districtAdmin="districtAdmin"
          ></district-admin-details>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row dense v-if="meetingScheduled">
      <v-col cols="6" md="3">
        <v-label>Meeting Date</v-label>
      </v-col>
      <v-col cols="6" md="4" class="pt-0">
        <date-picker
          :value="meetingDate"
          :field="{}"
          @fieldChange="meetingDate = $event"
          :readonly="noEdit"
        >
        </date-picker>
      </v-col>
    </v-row>
    <v-row dense v-if="meetingScheduled">
      <v-col cols="6" md="3">
        <v-label>Meeting Time</v-label>
      </v-col>
      <v-col cols="6" md="3" class="pt-0">
        <time-input-field
          :value="evaluation.meetingStartTime"
          :field="{}"
          @fieldChange="evaluation.meetingStartTime = $event"
          :readonly="noEdit"
        >
        </time-input-field>
      </v-col>
    </v-row>
    <v-row dense v-if="meetingScheduled">
      <v-col cols="6" md="3">
        <v-label>Phone Number</v-label>
      </v-col>
      <v-col cols="6" md="5" class="pt-0">
        <v-text-field
          v-model="evaluation.meetingPhoneNumber"
          dense
          :readonly="noEdit"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense v-if="meetingScheduled">
      <v-col cols="6" md="3">
        <v-label>Eligible For Services?</v-label>
      </v-col>
      <v-col cols="6" md="4" class="pt-0">
        <v-radio-group
          row
          v-model="evaluation.isEligibleForServices"
          :readonly="noEdit"
        >
          <v-radio :value="true" label="Yes"></v-radio>
          <v-radio :value="false" label="No"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row class="justify-center" v-if="evaluation.isEligibleForServices">
      <v-toolbar flat>
        <v-toolbar-title>Approved Mandates</v-toolbar-title>
      </v-toolbar>
      <mandates-table
        :mandates="evaluation.approvedMandates"
        :noEdit="noEdit"
      ></mandates-table>
    </v-row>
    <v-row
      class="justify-center my-4"
      v-if="!noEdit && evaluation.isEligibleForServices"
    >
      <mandate-modal :newMandate="true"></mandate-modal>
    </v-row>
    <v-row>
      <v-col cols="6" md="3">
        <v-checkbox
          v-model="caseComplete"
          label="Case Completed"
          :disabled="noEdit"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" md="4">
        <date-picker
          v-if="caseComplete"
          :value="completedAt"
          :field="{}"
          @fieldChange="completedAt = $event"
          :readonly="noEdit"
          :validations="{ required: completedAt }"
        >
        </date-picker>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MandatesTable from "./mandates-table.vue";
import MandateModal from "./mandate-modal.vue";
import DistrictAdminDetails from "./district-admin-details.vue";
import TimeInputField from "../FormFields/time-input-field.vue";
export default {
  props: ["noEdit"],
  components: {
    MandatesTable,
    MandateModal,
    DistrictAdminDetails,
    TimeInputField,
  },
  async mounted() {
    this.meetingScheduled = Boolean(this.meetingScheduledAt);
    this.parentConfirmed = Boolean(this.parentConfirmedMeetingAt);
    this.schoolConfirmed = Boolean(this.schoolConfirmedMeetingAt);
    this.caseComplete = Boolean(this.completedAt);
  },
  data() {
    return {
      meetingScheduled: false,
      parentConfirmed: false,
      schoolConfirmed: false,
      caseComplete: false,
    };
  },
  computed: {
    districtAdmins() {
      return this.$store.state.optionsModule.districtAdmins || [];
    },
    providers() {
      return this.$store.state.optionsModule.providers || [];
    },
    evaluation: {
      get() {
        return this.$store.state.evaluationsModule.selectedEval;
      },
      set(newVal) {
        return this.$store.dispatch("evaluationsModule/updateSelectedEval", {
          evaluation: newVal,
        });
      },
    },
    meetingScheduledAt: {
      get() {
        return this.evaluation.meetingScheduledAt
          ? new Date(this.evaluation.meetingScheduledAt)
          : "";
      },
      set(newVal) {
        this.evaluation.meetingScheduledAt = newVal;
      },
    },
    parentConfirmedMeetingAt: {
      get() {
        return this.evaluation.parentConfirmedMeetingAt
          ? new Date(this.evaluation.parentConfirmedMeetingAt)
          : "";
      },
      set(newVal) {
        this.evaluation.parentConfirmedMeetingAt = newVal;
      },
    },
    schoolConfirmedMeetingAt: {
      get() {
        return this.evaluation.schoolConfirmedMeetingAt
          ? new Date(this.evaluation.schoolConfirmedMeetingAt)
          : "";
      },
      set(newVal) {
        this.evaluation.schoolConfirmedMeetingAt = newVal;
      },
    },
    meetingDate: {
      get() {
        return this.evaluation.meetingDate
          ? new Date(this.evaluation.meetingDate)
          : "";
      },
      set(newVal) {
        this.evaluation.meetingDate = newVal;
      },
    },
    completedAt: {
      get() {
        return this.evaluation.completedAt
          ? new Date(this.evaluation.completedAt)
          : "";
      },
      set(newVal) {
        this.evaluation.completedAt = newVal;
      },
    },
    districtAdmin() {
      return this.$store.getters["optionsModule/districtAdmin"](
        this.evaluation.districtAdminId
      );
    },
  },
};
</script>
