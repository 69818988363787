import Service from "./Service";

export default class Program {
  programId?: number;
  code?: string | null;
  name?: string | null;
  services?: Service[] | null;

  public constructor(params: Program = {} as Program) {
    let { programId = 0, code = "", name = "", services = null } = params;
    this.programId = programId;
    this.code = code;
    this.name = name;
    this.services = services && services.map((i) => new Service(i));
  }
}
