<template>
  <validation-provider :rules="{ required: true }" v-slot="{ errors }">
    <v-combobox
      :value="noteSubject"
      @change="setNoteSubject"
      :items="noteSubjectOptions"
      item-text="label"
      item-key="subjectId"
      label="Choose Subject"
      clearable
      dense
      outlined
      prepend-icon="mdi-text-subject"
      class="mt-2"
      :error-messages="errors"
    ></v-combobox>
  </validation-provider>
</template>
<script>
export default {
  props: ["noteSubject"],
  computed: {
    noteSubjects() {
      return this.$store.state.optionsModule.noteSubjects;
    },
    noteSubjectOptions() {
      return [
        {
          header: "Select one or type other option",
        },
      ].concat(this.noteSubjects);
    },
  },
  methods: {
    setNoteSubject(event) {
      this.$emit("setNoteSubject", event);
    },
  },
};
</script>
