<template>
  <v-dialog v-model="dialog" width="625">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="newAssessment"
        x-small
        rounded
        @click="showDialog"
        color="primary"
        ><v-icon x-small>mdi-plus</v-icon>Add Assessment</v-btn
      >
      <v-tooltip v-else bottom v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn text x-small v-on="on" @click="showDialog">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </span>
        </template>
        <span> Edit details </span>
      </v-tooltip>
    </template>

    <v-card class="primary--text pt-4" v-if="dialog">
      <validation-observer ref="form" v-slot="{ invalid }">
        <validation-provider
          name="service"
          :rules="validations.service"
          v-slot="{ errors }"
        >
          <v-card-title>
            <v-autocomplete
              v-model="assessment.serviceId"
              :items="evalServices"
              :item-text="(item) => `${item.code} - ${item.name}`"
              item-value="serviceId"
              label="Service"
              dense
              :error-messages="errors"
            ></v-autocomplete>
          </v-card-title>
        </validation-provider>

        <v-card-text class="pt-4">
          <v-row dense v-if="assessment.rationaleRequired">
            <v-col cols="4">
              <v-subheader>Rationale Submitted On</v-subheader>
            </v-col>
            <v-col cols="6">
              <date-picker
                :value="rationaleSubmittedAtDate"
                :field="{}"
                @fieldChange="rationaleSubmittedAtDate = $event"
              ></date-picker>
            </v-col>
          </v-row>
          <v-row dense v-if="assessment.rationaleRequired">
            <v-col cols="4">
              <v-subheader>Rationale Responded On</v-subheader>
            </v-col>
            <v-col cols="6">
              <date-picker
                :value="rationaleRespondedAtDate"
                :field="{}"
                @fieldChange="rationaleRespondedAtDate = $event"
                :validations="validations.rationaleRespondedAtDate"
              ></date-picker>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-subheader>Assigned To</v-subheader>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="assessment.providerId"
                :items="providers"
                :item-text="
                  (item) =>
                    `${item.firstName} ${item.lastName} - ${item.legacyProviderId}`
                "
                item-value="providerId"
                label="Provider"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-subheader>Assigned On</v-subheader>
            </v-col>
            <v-col cols="6">
              <date-picker
                :value="providerAssignedAtDate"
                :field="{}"
                @fieldChange="providerAssignedAtDate = $event"
              ></date-picker>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-subheader>Responded On</v-subheader>
            </v-col>
            <v-col cols="6">
              <date-picker
                :value="providerRespondedAtDate"
                :field="{}"
                @fieldChange="providerRespondedAtDate = $event"
                :validations="validations.providerRespondedAtDate"
              ></date-picker>
            </v-col>
          </v-row>
          <v-row dense class="pl-4" v-if="providerRespondedAtDate">
            <validation-provider
              :rules="validations.isAccepted"
              v-slot="{ errors }"
            >
              <v-radio-group row v-model="isAccepted" :error-messages="errors">
                <v-radio label="Accepted" :value="true"></v-radio>
                <v-radio label="Declined" :value="false"></v-radio>
              </v-radio-group>
            </validation-provider>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-subheader>Assessment Date</v-subheader>
            </v-col>
            <v-col cols="6">
              <date-picker
                :value="assessmentDate"
                :field="{}"
                @fieldChange="assessmentDate = $event"
                :validations="validations.assessmentDate"
              ></date-picker>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-subheader>Location</v-subheader>
            </v-col>
            <v-col cols="6">
              <validation-provider
                :rules="{ required: Boolean(assessmentDate) }"
                v-slot="{ errors }"
                ><v-select
                  v-model="assessment.sessionLocationId"
                  :items="locations"
                  item-text="name"
                  item-value="sessionLocationId"
                  dense
                  :error-messages="errors"
                ></v-select></validation-provider
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-subheader>Language</v-subheader>
            </v-col>
            <v-col cols="6">
              <validation-provider
                :rules="{ required: Boolean(assessmentDate) }"
                v-slot="{ errors }"
                ><v-select
                  v-model="assessment.languageId"
                  :items="languages"
                  item-text="name"
                  item-value="languageId"
                  dense
                  :error-messages="errors"
                ></v-select></validation-provider
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-subheader>Report Received On</v-subheader>
            </v-col>
            <v-col cols="6">
              <date-picker
                :value="reportSubmittedAtDate"
                :field="{}"
                @fieldChange="reportSubmittedAtDate = $event"
                :validations="validations.reportSubmittedAtDate"
              ></date-picker>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :dark="!loading"
            :disabled="loading"
            text
            @click="clear"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            :dark="!invalid && !loading"
            :disabled="invalid || loading"
            :loading="loading"
            outlined
            @click="save"
            >Save</v-btn
          >
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import Assessment from "../../objs/Assessment";
import {
  required,
  distinct,
  minDate,
} from "../../plugins/vee-validate/validation";
export default {
  props: ["assessmentInput", "newAssessment"],
  data() {
    return {
      dialog: false,
      loading: false,
      assessment: this.assessmentInput || new Assessment(),
      rationaleSubmittedAtDate: this.assessmentInput?.rationaleSubmittedAt
        ? new Date(this.assessmentInput?.rationaleSubmittedAt)
        : "",
      rationaleRespondedAtDate: this.assessmentInput?.rationaleRespondedAt
        ? new Date(this.assessmentInput?.rationaleRespondedAt)
        : "",
      providerAssignedAtDate: this.assessmentInput?.assignedAt
        ? new Date(this.assessmentInput?.assignedAt)
        : "",
      providerRespondedAtDate: this.assessmentInput?.providerRespondedAt
        ? new Date(this.assessmentInput?.providerRespondedAt)
        : "",
      assessmentDate: this.assessmentInput?.assessmentDate
        ? new Date(this.assessmentInput?.assessmentDate)
        : "",
      reportSubmittedAtDate: this.assessmentInput?.reportSubmittedAt
        ? new Date(this.assessmentInput?.reportSubmittedAt)
        : "",
      isAccepted: null,
    };
  },
  mounted() {
    if (this.assessmentInput?.providerAccepted) {
      this.isAccepted = true;
    } else if (this.assessmentInput?.providerDeclined) {
      this.isAccepted = false;
    }
  },
  computed: {
    validations() {
      return {
        service: {
          required: true,
          distinct: [this.otherServiceIds],
        },
        rationaleRespondedAtDate: {
          minDate: this.rationaleSubmittedAtDate,
        },
        providerRespondedAtDate: {
          minDate: this.providerAssignedAtDate,
        },
        isAccepted: {
          required: Boolean(this.providerRespondedAtDate),
        },
        assessmentDate: {
          minDate: this.providerAssignedAtDate,
        },
        reportSubmittedAtDate: {
          minDate: this.assessmentDate,
        },
      };
    },
    otherServiceIds() {
      return this.$store.state.evaluationsModule.selectedEval?.assessments
        .filter((item) => item.assessmentId != this.assessment?.assessmentId)
        .map((item) => item.serviceId);
    },
    evalServices() {
      return this.$store.getters["optionsModule/servicesByProgram"](
        this.$store.state.evaluationsModule.selectedEval?.programId
      );
    },
    providers() {
      return this.$store.state.optionsModule.providers;
    },
    languages() {
      return this.$store.state.optionsModule.languages;
    },
    locations() {
      return this.$store.state.optionsModule.locations;
    },
    evaluationId() {
      return this.$store.state.evaluationsModule.selectedEval?.evaluationId;
    },
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },
    async save() {
      this.loading = true;
      let success;

      if (this.newAssessment) {
        success = await this.$store.dispatch(
          "evaluationsModule/createAssessment",
          {
            ...this.assessment,
            evaluationId: this.evaluationId,
            rationaleSubmittedAt: this.rationaleSubmittedAtDate,
            rationaleRespondedAt: this.rationaleRespondedAtDate,
            assignedAt: this.providerAssignedAtDate,
            providerRespondedAt: this.providerRespondedAtDate,
            ...(this.isAccepted === true && { providerAccepted: true }),
            ...(this.isAccepted === false && { providerDeclined: true }),
            assessmentDate: this.assessmentDate,
            authStartDate: this.authStartDate,
            authEndDate: this.authEndDate,
            reportSubmittedAt: this.reportSubmittedAtDate,
          }
        );
      } else {
        success = await this.$store.dispatch(
          "evaluationsModule/updateAssessment",
          {
            ...this.assessment,
            evaluationId: this.evaluationId,
            rationaleSubmittedAt: this.rationaleSubmittedAtDate,
            rationaleRespondedAt: this.rationaleRespondedAtDate,
            assignedAt: this.providerAssignedAtDate,
            providerRespondedAt: this.providerRespondedAtDate,
            ...(this.isAccepted === true && { providerAccepted: true }),
            ...(this.isAccepted === false && { providerDeclined: true }),
            assessmentDate: this.assessmentDate,
            authStartDate: this.authStartDate,
            authEndDate: this.authEndDate,
            reportSubmittedAt: this.reportSubmittedAtDate,
          }
        );
      }

      this.loading = false;

      if (!success) {
        let message = this.newAssessment
          ? "Error creating new assessment"
          : "Error updating assessment";
        this.$store.commit("uxModule/setSnackbarMsg", message);
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
      this.clear();
    },
    clear() {
      this.$refs.form.reset();
      this.dialog = false;

      this.assessment = this.assessmentInput || new Assessment();
      this.rationaleSubmittedAtDate = this.assessmentInput?.rationaleSubmittedAt
        ? new Date(this.assessmentInput?.rationaleSubmittedAt)
        : "";
      this.rationaleRespondedAtDate = this.assessmentInput?.rationaleRespondedAt
        ? new Date(this.assessmentInput?.rationaleRespondedAt)
        : "";
      this.providerAssignedAtDate = this.assessmentInput?.assignedAt
        ? new Date(this.assessmentInput?.assignedAt)
        : "";
      this.providerRespondedAtDate = this.assessmentInput?.providerRespondedAt
        ? new Date(this.assessmentInput?.providerRespondedAt)
        : "";
      this.assessmentDate = this.assessmentInput?.assessmentDate
        ? new Date(this.assessmentInput?.assessmentDate)
        : "";
      this.authStartDate = this.assessmentInput?.authStartDate
        ? new Date(this.assessmentInput?.authStartDate)
        : "";
      this.authEndDate = this.assessmentInput?.authEndDate
        ? new Date(this.assessmentInput?.authEndDate)
        : "";
      this.reportSubmittedAtDate = this.assessmentInput?.reportSubmittedAt
        ? new Date(this.assessmentInput?.reportSubmittedAt)
        : "";
      this.isAccepted = null;
      if (this.assessmentInput?.providerAccepted) {
        this.isAccepted = true;
      } else if (this.assessmentInput?.providerDeclined) {
        this.isAccepted = false;
      }
    },
  },
};
</script>
