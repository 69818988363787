export default class FormField {
  label: string;
  value: string | boolean | number | Date | any[] | null;

  //Options
  options: any[];
  itemText: string | null;
  itemValue: string | null;

  //Styling
  outlined: boolean;
  public constructor(init?: Partial<FormField>) {
    Object.assign(this, init);
  }
}
