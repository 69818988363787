import Client from "../../objs/Client";
import Provider from "../../objs/Provider";
import Program from "../../objs/Program";
import Service from "../../objs/Service";
import DistrictAdmin from "../../objs/DistrictAdmin";
import Language from "../../objs/Language";
import Location from "../../objs/SessionLocation";
import * as api from "../Services/api.js";

export const state = {
  clients: [],
  providers: [],
  programs: [],
  services: [],
  districtAdmins: [],
  languages: [],
  locations: [],
  evaluationTypes: ["Initial", "EV1P"],
  noteSubjects: [],
};

export const mutations = {
  SET_CLIENTS(state, payload) {
    state.clients = payload
      .map((item) => new Client(item))
      .sort((a, b) => a.lastName.localeCompare(b.lastName));
  },
  SET_PROVIDERS(state, payload) {
    state.providers = payload
      .map((item) => new Provider(item))
      .sort((a, b) => a.lastName.localeCompare(b.lastName));
  },
  SET_PROGRAMS(state, payload) {
    state.programs = payload
      .map((item) => new Program(item))
      .sort((a, b) => a.name.localeCompare(b.name));
  },
  SET_SERVICES(state, payload) {
    state.services = payload
      .map((item) => new Service(item))
      .sort((a, b) => a.name.localeCompare(b.name));
  },
  SET_DISTRICT_ADMINS(state, payload) {
    state.districtAdmins = payload
      .map((item) => new DistrictAdmin(item))
      .sort((a, b) => a.lastName.localeCompare(b.lastName));
  },
  SET_LANGUAGES(state, payload) {
    state.languages = payload
      .map((item) => new Language(item))
      .sort((a, b) => a.name.localeCompare(b.name));
  },
  SET_LOCATIONS(state, payload) {
    state.locations = payload
      .map((item) => new Location(item))
      .sort((a, b) => a.name.localeCompare(b.name));
  },
  SET_NOTE_SUBJECTS(state, payload) {
    state.noteSubjects = payload;
  },
};

export const getters = {
  program: (state) => (programId) => {
    return state.programs.find((item) => item.programId == programId)?.name;
  },
  service: (state) => (serviceId) => {
    return state.services.find((item) => item.serviceId == serviceId)?.name;
  },
  language: (state) => (languageId) => {
    return state.languages.find((item) => item.languageId == languageId)?.name;
  },
  location: (state) => (locationId) => {
    return state.locations.find((item) => item.sessionLocationId == locationId)
      ?.name;
  },
  districtAdmin: (state) => (districtAdminId) => {
    return state.districtAdmins.find(
      (item) => item.districtAdminId == districtAdminId
    );
  },
  servicesByProgram: (state) => (programId) => {
    return state.programs
      .find((item) => item.programId === programId)
      ?.services.sort((a, b) => a.name.localeCompare(b.name));
  },
};

export const actions = {
  async getAllClients({ commit }) {
    return await api
      .get("/options/getAllClients")
      .then((response) => {
        if (response.success) {
          commit("SET_CLIENTS", response.data);
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async importClient({ commit, dispatch }, data) {
    return await api
      .postQuery("/boeImport/importSingleClient", data)
      .then((response) => {
        if (response.success) {
          dispatch("getAllClients");
        }

        return response.success;
      })
      .catch((error) => {
        return false;
      });
  },
  async getAllProviders({ commit }) {
    return await api.get("/provider/getAllProviders/").then((response) => {
      if (response.success) {
        commit("SET_PROVIDERS", response.data);
      }
    });
  },
  async getAllOptions({ commit }) {
    await api.get("/options/evaluationsOptions").then((response) => {
      if (response.success) {
        commit("SET_PROGRAMS", response.data?.programs);
        commit("SET_SERVICES", response.data?.services);
        commit("SET_DISTRICT_ADMINS", response.data?.districtAdmins);
        commit("SET_LANGUAGES", response.data?.languages);
        commit("SET_LOCATIONS", response.data?.locations);
        commit("SET_NOTE_SUBJECTS", response.data?.noteSubjects);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
