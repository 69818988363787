<template>
  <v-dialog v-model="dialog" width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        tile
        outlined
        depressed
        color="primary"
        dark
        v-on="on"
        v-bind="attrs"
        @click="dialog = true"
        class="mx-2"
        ><v-icon small color="primary">mdi-database-import</v-icon>Import
        Client</v-btn
      >
    </template>

    <v-card>
      <validation-observer v-slot="{ invalid }">
        <v-card-title>Import Client</v-card-title>
        <v-card-text class="text--center">
          <validation-provider :rules="{ required: true }" v-slot="{ errors }">
            <v-text-field
              v-model.number="legacyClientId"
              label="Enter the Legacy Client Id"
              type="number"
              dense
              class="ma-3"
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :dark="!loading"
            :disabled="loading"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            :dark="!invalid && !loading"
            :disabled="invalid || loading"
            :loading="loading"
            @click="importClient"
          >
            Import
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      legacyClientId: "",
    };
  },
  methods: {
    async importClient() {
      this.loading = true;
      let success = await this.$store.dispatch("optionsModule/importClient", {
        legacyClientId: this.legacyClientId,
      });
      this.loading = false;

      let message = success
        ? "Imported client successfully"
        : "Error importing client";
      this.$store.commit("uxModule/setSnackbarMsg", message);
      this.$store.commit("uxModule/setShowSnackbar", true);

      this.dialog = false;
    },
  },
};
</script>
