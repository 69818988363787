<template>
  <v-dialog v-model="dialog" width="900" max-height="700">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip top v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn fab icon large @click="dialog = true" color="primary"
              ><v-icon>mdi-comment-text-multiple-outline</v-icon></v-btn
            >
          </span>
        </template>
        <span> {{ tooltipTitle }}</span>
      </v-tooltip>
    </template>

    <v-card v-if="dialog">
      <v-card-title
        >Case Notes
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <validation-observer ref="form" v-slot="{ invalid }">
          <v-row dense>
            <v-col cols="12" sm="6">
              <note-subject-select
                :noteSubject="noteSubject"
                @setNoteSubject="setNoteSubject"
              ></note-subject-select>
            </v-col>
          </v-row>
          <v-row dense class="mt-0">
            <v-col cols="11" sm="9">
              <form-field
                :field="textEditor"
                @fieldChange="newNoteText = $event"
                :validations="{ required: true }"
              ></form-field>
            </v-col>
            <v-col
              cols="1"
              v-if="!noEdit"
              class="d-flex align-start justify-start"
            >
              <v-checkbox v-model="isInternal">
                <template v-slot:label>
                  <span style="font-size: 10px">Internal</span>
                </template></v-checkbox
              >
            </v-col>
            <v-col cols="12" sm="2" class="d-flex align-start justify-start">
              <v-btn
                text
                color="primary"
                :dark="!invalid && !loading"
                :disabled="invalid || loading"
                :loading="loading"
                @click="saveNote"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </validation-observer>

        <notes-table :notes="filteredNotes" :noEdit="noEdit"></notes-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import NotesTable from "./notes-table.vue";
import FormField from "../../objs/Shared/FormField";
import NoteSubjectSelect from "./note-subject-select.vue";
export default {
  props: ["noEdit", "noteSubjectId"],
  components: {
    NotesTable,
    NoteSubjectSelect,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      newNoteText: "",
      isInternal: false,
      noteSubject: "",
    };
  },
  async mounted() {
    if (this.noteSubjectId) {
      this.noteSubject = this.noteSubjects.find(
        (item) => item.subjectId == this.noteSubjectId
      );
    }
  },
  computed: {
    tooltipTitle() {
      if (this.noteSubjectId) {
        return `Case Notes - ${this.noteSubject.label}`;
      }
      return "All Case Notes";
    },
    evaluation() {
      return this.$store.state.evaluationsModule.selectedEval;
    },
    filteredNotes() {
      let filteredNotes = this.evaluation?.notes;
      if (this.noEdit) {
        filteredNotes = filteredNotes.filter((item) => !item.isInternal);
      }
      if (this.noteSubject) {
        filteredNotes = filteredNotes.filter((item) => {
          if (this.isCustomSubject) {
            return item.customSubject == this.noteSubject;
          } else {
            return item.subjectId == this.noteSubject?.subjectId;
          }
        });
      }
      return filteredNotes || [];
    },
    noteSubjects() {
      return this.$store.state.optionsModule.noteSubjects;
    },
    noteSubjectOptions() {
      return [
        {
          header: "Select one or type other option",
        },
      ].concat(this.noteSubjects);
    },
    textEditor() {
      return new FormField({
        value: this.newNoteText,
        label: "Enter new note",
        icon: "mdi-comment",
        componentName: "text-area",
      });
    },
    isCustomSubject() {
      return typeof this.noteSubject === "string";
    },
  },
  methods: {
    setNoteSubject(event) {
      this.noteSubject = event;
    },
    async saveNote() {
      this.loading = true;
      let success = await this.$store.dispatch("evaluationsModule/saveNote", {
        evaluationId: this.evaluation?.evaluationId,
        noteText: this.newNoteText,
        isInternal: this.isInternal,
        ...(!this.isCustomSubject && {
          subjectId: this.noteSubject?.subjectId,
        }),
        ...(this.isCustomSubject && {
          customSubject: this.noteSubject,
        }),
      });
      this.loading = false;

      if (!success) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error saving note");
        this.$store.commit("uxModule/setShowSnackbar", true);
      }

      this.clear();
    },
    close() {
      this.clear();
      this.dialog = false;
    },
    clear() {
      this.$refs.form.reset();
      this.newNoteText = "";
      this.isInternal = false;
    },
  },
};
</script>
