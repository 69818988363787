import { formatDate, formatTime } from "../utils/format-utils";

export default class Note {
  evaluationNoteId?: number;
  evaluationId?: number | null;
  noteText?: string | null;
  createdAt?: Date | null;
  isInternal?: boolean | null;
  subjectId?: number | null;
  subject?: string | null;
  customSubject?: string | null;
  userId?: number | null;
  firstName?: string | null;
  lastName?: string | null;

  public constructor(params: Note = {} as Note) {
    let {
      evaluationNoteId = 0,
      evaluationId = 0,
      noteText = "",
      createdAt = null,
      isInternal = false,
      subjectId = 0,
      subject = "",
      customSubject = "",
      userId = null,
      firstName = "",
      lastName = "",
    } = params;
    this.evaluationNoteId = evaluationNoteId;
    this.evaluationId = evaluationId;
    this.noteText = noteText;
    this.createdAt = createdAt;
    this.isInternal = isInternal;
    this.subjectId = subjectId;
    this.subject = subject;
    this.customSubject = customSubject;
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
  }

  get formattedDate() {
    return formatDate(this.createdAt) + " " + formatTime(this.createdAt);
  }

  get name() {
    if (this.firstName && this.lastName)
      return this.firstName + " " + this.lastName;
  }

  get subjectValue() {
    return this.subjectId && !this.customSubject
      ? this.subject
      : this.customSubject;
  }
}
