<template>
  <v-card outlined min-width="150" min-height="250" class="primary--text">
    <v-card-title>{{ client.name }}</v-card-title>
    <v-card-text>
      <div class="mb-1">
        Legacy Id:
        <span class="font-weight-bold">{{ client.legacyClientId }}</span>
      </div>
      <div class="my-1">
        DOB:
        <span class="font-weight-bold">{{
          formatDate(client.dateOfBirth)
        }}</span>
      </div>
      <div class="my-1">
        OSIS: <span class="font-weight-bold">{{ client.osis }}</span>
      </div>
      <v-divider class="my-3"></v-divider>
      <div class="my-1">
        Address:
        <span class="font-weight-bold"
          >{{ client.streetAddress }} {{ client.city }} {{ client.state }}
          {{ client.zipCode }}</span
        >
      </div>
      <div class="my-1">
        Home Phone:
        <span class="font-weight-bold">{{ client.homePhone }}</span>
      </div>
      <div class="my-1" v-if="client.mobilePhone">
        Mobile Phone:
        <span class="font-weight-bold">{{ client.mobilePhone }}</span>
      </div>
      <div class="my-1" v-if="client.emailAddress">
        Email:
        <span class="font-weight-bold">{{ client.emailAddress }}</span>
      </div>
      <div
        class="my-1"
        v-if="client.caregiverFirstName && client.caregiverLastName"
      >
        Parent:
        <span class="font-weight-bold"
          >{{ client.caregiverFirstName }} {{ client.caregiverLastName }}</span
        >
      </div>
      <div class="my-1">
        District:
        <span class="font-weight-bold">{{ client.districtId }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { formatDate } from "../../utils/format-utils";
export default {
  props: ["client"],
  methods: {
    formatDate,
  },
};
</script>
