<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        x-small
        color="primary"
        shaped
        class="ml-3"
        v-on="on"
        @click="dialog = true"
        ><v-icon small>mdi-filter-variant</v-icon> More Filters</v-btn
      >
    </template>

    <v-card class="primary--text">
      <v-card-title>
        <span><v-icon>mdi-filter-variant</v-icon> More Filters</span>
      </v-card-title>
      <v-card-text>
        <div class="px-4">
          <v-select
            v-model="typeFilter"
            :items="types"
            label="Type"
            outlined
            dense
            class="my-1"
          ></v-select>

          <v-select
            v-model="referralFilter"
            :items="referralOptions"
            label="Referral Packet Status"
            outlined
            dense
            multiple
            chips
            class="my-1"
          ></v-select>

          <form-field
            :field="zipCodeField"
            @fieldChange="onZipCodeChange"
            class="my-1"
          ></form-field>

          <form-field
            :field="districtField"
            @fieldChange="onDistrictChange"
            class="my-1"
          ></form-field>

          <v-autocomplete
            v-model="districtAdminFilter"
            :items="districtAdmins"
            label="District Administrator"
            clearable
            :item-text="(item) => `${item.lastName}, ${item.firstName}`"
            item-value="districtAdminId"
            hide-details
            dense
            outlined
            class="my-1"
          ></v-autocomplete>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark text small @click="clear">Clear All</v-btn>
        <v-btn color="primary" dark outlined small @click="apply">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import FormField from "../../objs/Shared/FormField";
import { FieldTypes } from "../../objs/Shared/FieldTypes";
import { ReferralStatus } from "../../objs/ReferralStatus";
export default {
  data() {
    return {
      dialog: false,
      typeFilter: null,
      referralFilter: null,
      zipCodeFilter: null,
      districtFilter: null,
      districtAdminFilter: null,
    };
  },
  computed: {
    zipCodeField() {
      return new FormField({
        label: "Zip Code",
        value: this.zipCodeFilter,
        componentName: FieldTypes.ZIP_CODE_FIELD,
        outlined: true,
      });
    },
    districtField() {
      return new FormField({
        label: "District",
        value: this.districtFilter,
        componentName: FieldTypes.NUMERIC_FIELD,
        outlined: true,
      });
    },
    districtAdmins() {
      return this.$store.state.optionsModule.districtAdmins || [];
    },
    types() {
      return this.$store.state.optionsModule.evaluationTypes;
    },
    referralOptions() {
      return Object.values(ReferralStatus);
    },
  },
  methods: {
    onZipCodeChange(value) {
      this.zipCodeFilter = value;
    },
    onDistrictChange(value) {
      this.districtFilter = value;
    },
    clear() {
      this.typeFilter = null;
      this.referralFilter = null;
      this.zipCodeFilter = null;
      this.districtFilter = null;
      this.districtAdminFilter = null;
      this.$emit("clearAll");
      this.dialog = false;
    },
    apply() {
      this.$emit("apply", {
        typeFilter: this.typeFilter,
        referralFilter: this.referralFilter,
        zipCodeFilter: this.zipCodeFilter,
        districtFilter: this.districtFilter,
        districtAdminFilter: this.districtAdminFilter,
      });
      this.dialog = false;
    },
  },
};
</script>
