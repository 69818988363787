<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-btn
          text
          x-small
          :dark="isPreviewAvailable"
          :disabled="!isPreviewAvailable"
          color="primary"
          v-on="on"
          v-on:click="previewFile"
          ><v-icon small>mdi-file-eye-outline</v-icon></v-btn
        >
      </span>
    </template>
    <span> {{ title }} </span>
  </v-tooltip>
</template>

<script>
export default {
  props: ["fileId", "file", "disabled"],
  computed: {
    isPreviewAvailable: function () {
      return !this.disabled && this.fileId && this.file?.isPreviewAvailable;
    },
    title: function () {
      return this.isPreviewAvailable ? "Preview" : "Preview Not Available";
    },
  },
  methods: {
    previewFile: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      var fileUrl = await this.$store.dispatch("filesModule/getFileUrl", {
        fileId: this.fileId,
      });
      this.$store.commit("uxModule/setShowLoader", false);

      if (fileUrl) {
        window.open(fileUrl);
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
