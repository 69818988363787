<template>
  <div>
    <div class="text-subtitle-1">{{ districtAdmin.name }}</div>
    <div class="my-1" v-if="districtAdmin.streetAddress">
      Address:
      <span class="font-weight-bold"
        >{{ districtAdmin.streetAddress }} {{ districtAdmin.city }}
        {{ districtAdmin.state }} {{ districtAdmin.zipCode }}</span
      >
    </div>
    <div class="my-1" v-if="districtAdmin.phone">
      Phone:
      <span class="font-weight-bold">{{ districtAdmin.phone }}</span>
    </div>
    <div class="my-1" v-if="districtAdmin.emailAddress">
      Email:
      <span class="font-weight-bold">{{ districtAdmin.emailAddress }}</span>
    </div>
    <div
      class="my-1"
      v-if="
        !districtAdmin.streetAddress &&
        !districtAdmin.phone &&
        !districtAdmin.emailAddress
      "
    >
      No contact info available
    </div>
  </div>
</template>
<script>
export default {
  props: ["districtAdmin"],
};
</script>
