<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="3" class="align--center">
        <v-label>Referred On</v-label>
      </v-col>
      <v-col cols="12" sm="4" class="pt-0">
        <date-picker
          :value="referralDate"
          :field="{}"
          @fieldChange="referralDate = $event"
          :readonly="noEdit"
        ></date-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" class="align--center">
        <v-label>Referred By</v-label>
      </v-col>
      <v-col cols="12" sm="4" class="pt-0">
        <v-combobox
          v-model="evaluation.whoReferred"
          :items="whoReferredOptions"
          :readonly="noEdit"
        ></v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" class="align--center">
        <v-label>Initial Contact With Parent</v-label>
      </v-col>
      <v-col cols="12" sm="4" class="pt-0">
        <date-picker
          :value="initialContactDate"
          :field="{}"
          @fieldChange="initialContactDate = $event"
          :readonly="noEdit"
        ></date-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" class="align--center">
        <v-label>Parent Received Referral Packet</v-label>
      </v-col>
      <v-col cols="12" sm="4" class="pt-0">
        <date-picker
          :value="parentReceivedReferralPacketAt"
          :field="{}"
          @fieldChange="parentReceivedReferralPacketAt = $event"
          :readonly="noEdit"
        ></date-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" class="align--center">
        <v-label>Omni Received Referral Packet</v-label>
      </v-col>
      <v-col cols="12" sm="4" class="pt-0">
        <date-picker
          :value="omniReceivedReferralPacketAt"
          :field="{}"
          @fieldChange="omniReceivedReferralPacketAt = $event"
          :readonly="noEdit"
        ></date-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3" class="align--center">
        <v-label>District Administrator</v-label>
      </v-col>
      <v-col cols="12" sm="4" class="pt-0">
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-autocomplete
                v-model="evaluation.districtAdminId"
                :items="districtAdmins"
                :item-text="(item) => `${item.lastName}, ${item.firstName}`"
                item-value="districtAdminId"
                dense
                :readonly="noEdit"
              ></v-autocomplete
            ></span>
          </template>
          <district-admin-details
            v-if="districtAdmin"
            :districtAdmin="districtAdmin"
          ></district-admin-details>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DistrictAdminDetails from "./district-admin-details.vue";
export default {
  components: {
    DistrictAdminDetails,
  },
  props: ["noEdit"],
  computed: {
    districtAdmins() {
      return this.$store.state.optionsModule.districtAdmins || [];
    },
    whoReferredOptions() {
      return [
        {
          header: "Select one or type other option",
        },
        "Parent",
        "School",
        "EISC",
      ];
    },
    evaluation: {
      get() {
        return this.$store.state.evaluationsModule.selectedEval;
      },
      set(newVal) {
        this.$store.dispatch("evaluationsModule/updateSelectedEval", {
          evaluation: newVal,
        });
      },
    },
    referralDate: {
      get() {
        return this.evaluation.referralDate
          ? new Date(this.evaluation.referralDate)
          : "";
      },
      set(newVal) {
        this.evaluation.referralDate = newVal;
      },
    },
    initialContactDate: {
      get() {
        return this.evaluation.initialContactDate
          ? new Date(this.evaluation.initialContactDate)
          : "";
      },
      set(newVal) {
        this.evaluation.initialContactDate = newVal;
      },
    },
    parentReceivedReferralPacketAt: {
      get() {
        return this.evaluation.parentReceivedReferralPacketAt
          ? new Date(this.evaluation.parentReceivedReferralPacketAt)
          : "";
      },
      set(newVal) {
        this.evaluation.parentReceivedReferralPacketAt = newVal;
      },
    },
    omniReceivedReferralPacketAt: {
      get() {
        return this.evaluation.omniReceivedReferralPacketAt
          ? new Date(this.evaluation.omniReceivedReferralPacketAt)
          : "";
      },
      set(newVal) {
        this.evaluation.omniReceivedReferralPacketAt = newVal;
      },
    },
    districtAdmin() {
      return this.$store.getters["optionsModule/districtAdmin"](
        this.evaluation.districtAdminId
      );
    },
  },
};
</script>
