<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-btn
          text
          x-small
          :dark="isDownloadAvailable"
          :disabled="!isDownloadAvailable"
          color="primary"
          v-on="on"
          v-on:click="downloadFile"
        >
          <v-icon small>mdi-cloud-download-outline</v-icon>
        </v-btn>
      </span>
    </template>
    <span>{{ title }}</span>
  </v-tooltip>
</template>

<script>
import { downloadFile } from "../../utils/download-utils";
export default {
  props: ["fileId", "filename", "disabled"],
  computed: {
    isDownloadAvailable: function () {
      return !this.disabled && Boolean(this.fileId);
    },
    title: function () {
      return this.isDownloadAvailable ? "Download" : "Download Not Available";
    },
  },
  methods: {
    downloadFile: async function () {
      this.$store.commit("uxModule/setShowLoader", true);
      var fileUrl = await this.$store.dispatch("filesModule/getFileUrl", {
        fileId: this.fileId,
      });
      this.$store.commit("uxModule/setShowLoader", false);

      if (fileUrl) {
        downloadFile(fileUrl, this.filename);
      } else {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Oops! Something went wrong. Please try again later."
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
