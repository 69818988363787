import Provider from "./Provider";
import Service from "./Service";
import File from "./File";
import { formatDate } from "../utils/format-utils";
import Location from "./SessionLocation";
import Language from "./Language";

export default class Assessment {
  assessmentId?: number;
  evaluationId?: number | null;
  serviceId?: number | null;
  service?: Service | null;
  rationaleSubmittedAt?: Date | null;
  rationaleRespondedAt?: Date | null;
  providerId?: number | null;
  provider?: Provider | null;
  assignedAt?: Date | null;
  providerRespondedAt?: Date | null;
  providerAccepted?: Boolean | null;
  providerDeclined?: Boolean | null;
  assessmentDate?: Date | null;
  sessionLocationId?: number | null;
  sessionLocation?: Location | null;
  languageId?: number | null;
  language?: Language | null;
  reportSubmittedAt?: Date | null;
  fileId?: number | null;
  file?: File | null;

  public constructor(params: Assessment = {} as Assessment) {
    let {
      assessmentId = 0,
      evaluationId = 0,
      serviceId = null,
      service = null,
      rationaleSubmittedAt = null,
      rationaleRespondedAt = null,
      providerId = null,
      provider = null,
      assignedAt = null,
      providerRespondedAt = null,
      providerAccepted = false,
      providerDeclined = false,
      assessmentDate = null,
      sessionLocationId = null,
      sessionLocation = null,
      languageId = null,
      language = null,
      reportSubmittedAt = null,
      fileId = null,
      file = null,
    } = params;
    this.assessmentId = assessmentId;
    this.evaluationId = evaluationId;
    this.serviceId = serviceId;
    this.service = service && new Service(service);
    this.rationaleSubmittedAt = rationaleSubmittedAt;
    this.rationaleRespondedAt = rationaleRespondedAt;
    this.providerId = providerId;
    this.provider = provider && new Provider(provider);
    this.assignedAt = assignedAt;
    this.providerRespondedAt = providerRespondedAt;
    this.providerAccepted = providerAccepted;
    this.providerDeclined = providerDeclined;
    this.assessmentDate = assessmentDate;
    this.sessionLocationId = sessionLocationId;
    this.sessionLocation = sessionLocation && new Location(sessionLocation);
    this.languageId = languageId;
    this.language = language && new Language(language);
    this.reportSubmittedAt = reportSubmittedAt;
    this.fileId = fileId;
    this.file = file && new File(file);
  }

  get providerName() {
    if (this.provider)
      return this.provider?.firstName + " " + this.provider?.lastName;
    return "";
  }

  get formattedAssignedOn() {
    if (this.assignedAt) return formatDate(this.assignedAt);
  }

  get formattedAssessmentDate() {
    if (this.assessmentDate) return formatDate(this.assessmentDate);
  }

  get formattedReportSubmittedAt() {
    if (this.reportSubmittedAt) return formatDate(this.reportSubmittedAt);
  }

  get serviceName() {
    return this.service?.name + " (" + this.service?.code + ")";
  }

  get rationaleRequired() {
    // all except PSY and SOC
    return ![7, 8].includes(this.serviceId || 0);
  }

  get rationaleState() {
    if (!this.rationaleRequired) {
      return "N/A";
    } else if (this.rationaleRespondedAt) {
      return `Responded ${formatDate(this.rationaleRespondedAt)}`;
    } else if (this.rationaleSubmittedAt) {
      return `Submitted ${formatDate(this.rationaleSubmittedAt)}`;
    } else {
      return "Not Submitted";
    }
  }

  get filename() {
    return this.file?.fileName;
  }
}
