<template>
  <v-row>
    <v-col cols="12" sm="3">
      <client-details :client="client"></client-details>
      <v-spacer class="my-5"></v-spacer>
      <school-details
        v-if="school"
        :school="school"
        :subSchool="subSchool"
      ></school-details>
    </v-col>
    <v-col cols="12" sm="9">
      <evaluation-steps :noEdit="noEdit"></evaluation-steps>
    </v-col>
  </v-row>
</template>
<script>
import ClientDetails from "./client-details.vue";
import SchoolDetails from "./school-details.vue";
import EvaluationSteps from "./evaluation-steps.vue";
export default {
  components: {
    ClientDetails,
    SchoolDetails,
    EvaluationSteps,
  },
  props: ["noEdit"],
  async mounted() {
    if (this.$store.state.optionsModule.providers.length == 0)
      await this.$store.dispatch("optionsModule/getAllProviders");
  },
  computed: {
    evaluation() {
      return this.$store.state.evaluationsModule.selectedEval;
    },
    client() {
      return this.evaluation?.client;
    },
    school() {
      return this.client?.currentSchool;
    },
    subSchool() {
      return this.client?.currentSubSchool;
    },
  },
};
</script>
