import { formatPhone } from "../utils/format-utils";

export default class School {
  schoolId?: number;
  legacySchoolId?: number | null;
  schoolName?: string | null;
  summerCamp?: boolean;
  districtId?: number | null;
  schoolBoro?: string | null;
  schoolCode?: string | null;
  secondaryDistrictId?: number | null;
  secondarySchoolBoro?: string | null;
  secondarySchoolCode?: string | null;
  streetAddress?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  emailAddress?: string | null;
  phone?: string | null;
  phoneExtension?: string | null;
  secondaryPhone?: string | null;
  secondaryPhoneExtension?: string | null;
  fax?: string | null;
  contact?: string | null;
  notes?: string | null;

  public constructor(params: School = {} as School) {
    let {
      schoolId = 0,
      legacySchoolId = 0,
      schoolName = "",
      summerCamp = false,
      districtId = 0,
      schoolBoro = "",
      schoolCode = "",
      secondaryDistrictId = 0,
      secondarySchoolBoro = "",
      secondarySchoolCode = "",
      streetAddress = "",
      city = "",
      state = "",
      zipCode = "",
      emailAddress = "",
      phone = "",
      phoneExtension = "",
      secondaryPhone = "",
      secondaryPhoneExtension = "",
      fax = "",
      contact = "",
      notes = "",
    } = params;
    this.schoolId = schoolId;
    this.legacySchoolId = legacySchoolId;
    this.schoolName = schoolName;
    this.summerCamp = summerCamp;
    this.districtId = districtId;
    this.schoolBoro = schoolBoro;
    this.schoolCode = schoolCode;
    this.secondaryDistrictId = secondaryDistrictId;
    this.secondarySchoolBoro = secondarySchoolBoro;
    this.secondarySchoolCode = secondarySchoolCode;
    this.streetAddress = streetAddress;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.emailAddress = emailAddress;
    this.phone = phone && formatPhone(phone);
    this.phoneExtension = phoneExtension;
    this.secondaryPhone = secondaryPhone && formatPhone(secondaryPhone);
    this.secondaryPhoneExtension = secondaryPhoneExtension;
    this.fax = fax;
    this.contact = contact;
    this.notes = notes;
  }
}
