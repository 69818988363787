import Client from "./Client";
import DistrictAdmin from "./DistrictAdmin";
import Provider from "./Provider";
import { formatDate } from "../utils/format-utils";
import { EvaluationStatus } from "./EvaluationStatus";
import { ReferralStatus } from "./ReferralStatus";

export default class EvaluationDetails {
  evaluationId?: number;
  evaluationType?: string | null;
  clientId?: number | null;
  client?: Client | null;
  districtAdminId?: number | null;
  districtAdmin?: DistrictAdmin | null;
  completedAt?: Date | null;
  terminatedAt?: Date | null;
  releaseConsentSignedAt?: Date | null;
  referralDate?: Date | null;
  parentReceivedReferralPacketAt?: Date | null;
  omniReceivedReferralPacketAt?: Date | null;
  initialEvalConsentSignedAt?: Date | null;
  initialEvalConsentSentToDOEAt?: Date | null;
  summarySentToDOEAt?: Date | null;
  meetingRep?: Provider | null;
  meetingDate?: Date | null;

  public constructor(params: EvaluationDetails = {} as EvaluationDetails) {
    let {
      evaluationId = 0,
      evaluationType = "",
      clientId = null,
      client = null,
      districtAdminId = null,
      districtAdmin = null,
      completedAt = null,
      terminatedAt = null,
      releaseConsentSignedAt = null,
      referralDate = null,
      parentReceivedReferralPacketAt = null,
      omniReceivedReferralPacketAt = null,
      initialEvalConsentSignedAt = null,
      initialEvalConsentSentToDOEAt = null,
      summarySentToDOEAt = null,
      meetingRep = null,
      meetingDate = null,
    } = params;
    this.evaluationId = evaluationId;
    this.evaluationType = evaluationType;
    this.clientId = clientId;
    this.client = client && new Client(client);
    this.districtAdminId = districtAdminId;
    this.districtAdmin = districtAdmin && new DistrictAdmin(districtAdmin);
    this.completedAt = completedAt;
    this.terminatedAt = terminatedAt;
    this.releaseConsentSignedAt = releaseConsentSignedAt;
    this.referralDate = referralDate;
    this.parentReceivedReferralPacketAt = parentReceivedReferralPacketAt;
    this.omniReceivedReferralPacketAt = omniReceivedReferralPacketAt;
    this.initialEvalConsentSignedAt = initialEvalConsentSignedAt;
    this.initialEvalConsentSentToDOEAt = initialEvalConsentSentToDOEAt;
    this.summarySentToDOEAt = summarySentToDOEAt;
    this.meetingRep = meetingRep && new Provider(meetingRep);
    this.meetingDate = meetingDate;
  }

  get clientName() {
    return this.client?.name;
  }

  get clientDob() {
    return formatDate(this.client?.dateOfBirth);
  }

  get clientOsis() {
    return this.client?.osis;
  }

  get schoolName() {
    return this.client?.schoolName;
  }

  get schoolId() {
    return this.client?.currentSchool?.schoolId;
  }

  get subSchoolId() {
    return this.client?.currentSubSchool?.schoolId;
  }

  get clientDistrict() {
    return this.client?.districtId;
  }

  get status() {
    if (Boolean(this.terminatedAt)) {
      return EvaluationStatus.TERMINATED;
    } else if (Boolean(this.completedAt)) {
      return EvaluationStatus.COMPLETE;
    } else if (Boolean(this.summarySentToDOEAt)) {
      return EvaluationStatus.MEETING;
    } else if (Boolean(this.initialEvalConsentSentToDOEAt)) {
      return EvaluationStatus.ASSESSMENTS;
    } else {
      return EvaluationStatus.REFERRAL;
    }
  }

  get referralStatus() {
    if (Boolean(this.initialEvalConsentSentToDOEAt)) {
      return ReferralStatus.SENT_TO_DOE;
    } else if (Boolean(this.initialEvalConsentSignedAt)) {
      return ReferralStatus.SIGNED;
    } else if (Boolean(this.omniReceivedReferralPacketAt)) {
      return ReferralStatus.OMNI_RECEIVED;
    } else if (Boolean(this.parentReceivedReferralPacketAt)) {
      return ReferralStatus.PARENT_RECEIVED;
    } else {
      return ReferralStatus.PENDING;
    }
  }

  get formattedReferralDate() {
    return formatDate(this.referralDate);
  }

  get formattedInitialEvalConsentSentToDOEAt() {
    return formatDate(this.initialEvalConsentSentToDOEAt);
  }

  get formattedMeetingDate() {
    return formatDate(this.meetingDate);
  }

  get meetingRepName() {
    if (this.meetingRep)
      return this.meetingRep?.firstName + " " + this.meetingRep?.lastName;
  }
}
