<template>
  <v-container>
    <v-row dense>
      <v-col cols="6" md="4">
        <v-checkbox
          v-model="initialEvalConsentSigned"
          label="Consent For Initial Evaluation Signed"
          :disabled="noEdit"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" md="3">
        <date-picker
          v-if="initialEvalConsentSigned"
          :value="initialEvalConsentSignedAt"
          :field="{}"
          @fieldChange="initialEvalConsentSignedAt = $event"
          :readonly="noEdit"
          :validations="{ required: initialEvalConsentSigned }"
        >
        </date-picker>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" md="4">
        <v-checkbox
          v-model="initialEvalConsentSentToDOE"
          label="Consent For Initial Evaluation Sent To DOE"
          :disabled="noEdit"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" md="3">
        <date-picker
          v-if="initialEvalConsentSentToDOE"
          :value="initialEvalConsentSentToDOEAt"
          :field="{}"
          @fieldChange="initialEvalConsentSentToDOEAt = $event"
          :readonly="noEdit"
          :validations="{ required: initialEvalConsentSentToDOE }"
        >
        </date-picker>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <assessments-table
        :assessments="evaluation.assessments"
        :noEdit="noEdit"
        :summarySentToParentAt="evaluation.summarySentToParentAt"
      ></assessments-table>
    </v-row>
    <v-row class="justify-center my-4">
      <assessment-modal v-if="!noEdit" :newAssessment="true"></assessment-modal>
    </v-row>
    <v-row dense>
      <v-col cols="6" md="4">
        <v-checkbox
          v-model="summaryCreated"
          label="MDE Package Created"
          :disabled="noEdit || disableSummaryFields"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" md="3">
        <date-picker
          v-if="summaryCreated"
          :value="summaryCreatedAt"
          :field="{}"
          @fieldChange="summaryCreatedAt = $event"
          :readonly="noEdit || disableSummaryFields"
          :validations="{ required: summaryCreated }"
        >
        </date-picker>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" md="4">
        <v-checkbox
          v-model="summarySentToDOE"
          label="MDE Package Sent To DOE"
          :disabled="noEdit || disableSummaryFields"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" md="3">
        <date-picker
          v-if="summarySentToDOE"
          :value="summarySentToDOEAt"
          :field="{}"
          @fieldChange="summarySentToDOEAt = $event"
          :readonly="noEdit || disableSummaryFields"
          :validations="{ required: summarySentToDOE }"
        >
        </date-picker>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" md="4">
        <v-checkbox
          v-model="summarySentToParent"
          label="Evaluations Sent To Parent"
          :disabled="noEdit || disableSummaryFields"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" md="3">
        <date-picker
          v-if="summarySentToParent"
          :value="summarySentToParentAt"
          :field="{}"
          @fieldChange="summarySentToParentAt = $event"
          :readonly="noEdit || disableSummaryFields"
          :validations="{ required: summarySentToParent }"
        >
        </date-picker>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" md="4">
        <v-checkbox
          v-model="summaryReviewedWithParent"
          label="Evaluations Reviewed with Parent"
          :disabled="noEdit || disableSummaryFields"
        ></v-checkbox>
      </v-col>
      <v-col cols="6" md="3">
        <date-picker
          v-if="summaryReviewedWithParent"
          :value="summaryReviewedWithParentAt"
          :field="{}"
          @fieldChange="summaryReviewedWithParentAt = $event"
          :readonly="noEdit || disableSummaryFields"
          :validations="{ required: summaryReviewedWithParent }"
        >
        </date-picker>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AssessmentsTable from "./assessments-table.vue";
import AssessmentModal from "./assessment-modal.vue";
export default {
  props: ["noEdit"],
  components: {
    AssessmentsTable,
    AssessmentModal,
  },
  data() {
    return {
      initialEvalConsentSigned: false,
      initialEvalConsentSentToDOE: false,
      summaryCreated: false,
      summarySentToDOE: false,
      summarySentToParent: false,
      summaryReviewedWithParent: false,
    };
  },
  mounted() {
    this.initialEvalConsentSigned = Boolean(this.initialEvalConsentSignedAt);
    this.initialEvalConsentSentToDOE = Boolean(
      this.initialEvalConsentSentToDOEAt
    );
    this.summaryCreated = Boolean(this.summaryCreatedAt);
    this.summarySentToDOE = Boolean(this.summarySentToDOEAt);
    this.summarySentToParent = Boolean(this.summarySentToParentAt);
    this.summaryReviewedWithParent = Boolean(this.summaryReviewedWithParentAt);
  },
  computed: {
    disableSummaryFields() {
      // return true if any assigned assessments do not have report submitted
      return this.evaluation?.assessments.some((item) => {
        return Boolean(item.assignedAt)
          ? item.reportSubmittedAt == null
          : false;
      });
    },
    evaluation: {
      get() {
        return this.$store.state.evaluationsModule.selectedEval;
      },
      set(newVal) {
        return this.$store.dispatch("evaluationsModule/updateSelectedEval", {
          evaluation: newVal,
        });
      },
    },
    initialEvalConsentSignedAt: {
      get() {
        return this.evaluation.initialEvalConsentSignedAt
          ? new Date(this.evaluation.initialEvalConsentSignedAt)
          : "";
      },
      set(newVal) {
        this.evaluation.initialEvalConsentSignedAt = newVal;
      },
    },
    initialEvalConsentSentToDOEAt: {
      get() {
        return this.evaluation.initialEvalConsentSentToDOEAt
          ? new Date(this.evaluation.initialEvalConsentSentToDOEAt)
          : "";
      },
      set(newVal) {
        this.evaluation.initialEvalConsentSentToDOEAt = newVal;
      },
    },
    summaryCreatedAt: {
      get() {
        return this.evaluation.summaryCreatedAt
          ? new Date(this.evaluation.summaryCreatedAt)
          : "";
      },
      set(newVal) {
        this.evaluation.summaryCreatedAt = newVal;
      },
    },
    summarySentToDOEAt: {
      get() {
        return this.evaluation.summarySentToDOEAt
          ? new Date(this.evaluation.summarySentToDOEAt)
          : "";
      },
      set(newVal) {
        this.evaluation.summarySentToDOEAt = newVal;
      },
    },
    summarySentToParentAt: {
      get() {
        return this.evaluation.summarySentToParentAt
          ? new Date(this.evaluation.summarySentToParentAt)
          : "";
      },
      set(newVal) {
        this.evaluation.summarySentToParentAt = newVal;
      },
    },
    summaryReviewedWithParentAt: {
      get() {
        return this.evaluation.summaryReviewedWithParentAt
          ? new Date(this.evaluation.summaryReviewedWithParentAt)
          : "";
      },
      set(newVal) {
        this.evaluation.summaryReviewedWithParentAt = newVal;
      },
    },
  },
};
</script>
