<template>
  <v-data-table
    :headers="_headers"
    :items="sortedNotes"
    no-data-text=""
    hide-default-footer
    disable-pagination
  >
    <template v-slot:[`item.subject`]="{ item }">
      <v-menu v-model="item.menu" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <v-chip v-on="on" small>{{ item.subjectValue }}</v-chip>
        </template>
        <v-card>
          <v-card-title>
            <note-subject-select
              :noteSubject="item.subjectValue"
              @setNoteSubject="setNoteSubject($event, item)"
            ></note-subject-select>
          </v-card-title>
        </v-card>
      </v-menu>
    </template>
    <template v-slot:[`item.isInternal`]="{ item }">
      <v-simple-checkbox
        :value="Boolean(item.isInternal)"
        disabled
      ></v-simple-checkbox
    ></template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn v-if="canDelete(item)" text @click="deleteNote(item)">
        <v-icon small>mdi-delete</v-icon></v-btn
      >
    </template>
  </v-data-table>
</template>

<script>
import NoteSubjectSelect from "./note-subject-select.vue";
export default {
  components: { NoteSubjectSelect },
  props: ["notes", "noEdit"],
  mounted() {
    // update the current time every 60 seconds for the delete button timer
    this.interval = window.setInterval(
      (() => {
        return () => {
          this.currentTime = new Date();
        };
      })(),
      60000
    );
  },
  destroyed() {
    window.clearInterval(this.interval);
  },
  data() {
    return {
      headers: [
        { text: "Note", value: "noteText" },
        { text: "Subject", value: "subject", width: "5%" },
        {
          text: "Internal For Omni",
          value: "isInternal",
          hide: this.noEdit,
          width: "2%",
        },
        { text: "Created On", value: "formattedDate", width: "5%" },
        { text: "Created By", value: "name", width: "5%" },
        { text: "Actions", value: "actions", hide: this.noEdit, width: "1%" },
      ],
      interval: null,
      currentTime: new Date(),
    };
  },
  computed: {
    _headers() {
      return this.headers.filter((x) => !x.hide);
    },
    sortedNotes() {
      return this.notes.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
    isCustomSubject() {
      return typeof this.noteSubject === "string";
    },
  },
  methods: {
    canDelete(note) {
      // only show delete button if within 60 minutes of createdAt
      return (
        (this.currentTime - new Date(note.createdAt).getTime()) / 60000 <= 60
      );
    },
    async setNoteSubject(subject, note) {
      this.$store.commit("uxModule/setShowLoader", true);
      let success = await this.$store.dispatch("evaluationsModule/updateNote", {
        ...note,
        ...(!(typeof subject === "string") && {
          subjectId: subject?.subjectId,
          customSubject: "",
        }),
        ...(typeof subject === "string" && {
          subjectId: "",
          customSubject: subject,
        }),
      });
      this.$store.commit("uxModule/setShowLoader", false);

      if (!success) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error saving note");
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
    async deleteNote(item) {
      this.$store.commit("uxModule/setShowLoader", true);
      let message = await this.$store.dispatch("evaluationsModule/deleteNote", {
        noteId: item.evaluationNoteId,
      });
      this.$store.commit("uxModule/setShowLoader", false);
      if (message) {
        this.$store.commit("uxModule/setSnackbarMsg", message);
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
<style>
table {
  border-collapse: collapse;
}
</style>
