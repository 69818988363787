import Client from "./Client";
import Program from "./Program";
import DistrictAdmin from "./DistrictAdmin";
import Assessment from "./Assessment";
import Document from "./Document";
import Mandate from "./Mandate";
import File from "./File";
import Provider from "./Provider";
import Note from "./Note";
import { formatDate } from "../utils/format-utils";
import { EvaluationStatus } from "./EvaluationStatus";

export default class Evaluation {
  evaluationId?: number;
  clientId?: number | null;
  client?: Client | null;
  createdAt?: Date | null;
  lastUpdatedAt?: Date | null;
  completedAt?: Date | null;
  programId?: number | null;
  program?: Program | null;
  evaluationType?: string | null;
  districtAdminId?: number | null;
  districtAdmin?: DistrictAdmin | null;
  releaseConsentSignedAt?: Date | null;
  referralDate?: Date | null;
  whoReferred?: string | null;
  initialContactDate?: Date | null;
  parentReceivedReferralPacketAt?: Date | null;
  omniReceivedReferralPacketAt?: Date | null;
  initialEvalConsentSignedAt?: Date | null;
  initialEvalConsentSentToDOEAt?: Date | null;
  assessments?: Assessment[] | null;
  summaryCreatedAt?: Date | null;
  summarySentToDOEAt?: Date | null;
  summarySentToParentAt?: Date | null;
  summaryReviewedWithParentAt?: Date | null;
  summaryFileId?: number | null;
  summaryFile?: File | null;
  meetingScheduledAt?: Date | null;
  parentConfirmedMeetingAt?: Date | null;
  schoolConfirmedMeetingAt?: Date | null;
  meetingRepId?: number | null;
  meetingRep?: Provider | null;
  meetingDate?: Date | null;
  meetingStartTime?: Date | null;
  meetingPhoneNumber?: string | null;
  isEligibleForServices?: Boolean | null;
  approvedMandates?: Mandate[] | null;
  notes?: Note[] | null;
  documents?: Document[] | null;
  terminatedAt?: Date | null;

  public constructor(params: Evaluation = {} as Evaluation) {
    let {
      evaluationId = 0,
      clientId = null,
      client = null,
      createdAt = null,
      lastUpdatedAt = null,
      completedAt = null,
      programId = null,
      program = null,
      evaluationType = "",
      districtAdminId = null,
      districtAdmin = null,
      releaseConsentSignedAt = null,
      referralDate = null,
      whoReferred = "",
      initialContactDate = null,
      parentReceivedReferralPacketAt = null,
      omniReceivedReferralPacketAt = null,
      initialEvalConsentSignedAt = null,
      initialEvalConsentSentToDOEAt = null,
      assessments = [],
      summaryCreatedAt = null,
      summarySentToDOEAt = null,
      summarySentToParentAt = null,
      summaryReviewedWithParentAt = null,
      summaryFileId = null,
      summaryFile = null,
      meetingScheduledAt = null,
      parentConfirmedMeetingAt = null,
      schoolConfirmedMeetingAt = null,
      meetingRepId = null,
      meetingRep = null,
      meetingDate = null,
      meetingStartTime = null,
      meetingPhoneNumber = "",
      isEligibleForServices = false,
      approvedMandates = [],
      notes = [],
      documents = [],
      terminatedAt = null,
    } = params;
    this.evaluationId = evaluationId;
    this.clientId = clientId;
    this.client = client && new Client(client);
    this.createdAt = createdAt;
    this.lastUpdatedAt = lastUpdatedAt;
    this.completedAt = completedAt;
    this.programId = programId;
    this.program = program && new Program(program);
    this.evaluationType = evaluationType;
    this.districtAdminId = districtAdminId;
    this.districtAdmin = districtAdmin && new DistrictAdmin(districtAdmin);
    this.releaseConsentSignedAt = releaseConsentSignedAt;
    this.referralDate = referralDate;
    this.whoReferred = whoReferred;
    this.initialContactDate = initialContactDate;
    this.parentReceivedReferralPacketAt = parentReceivedReferralPacketAt;
    this.omniReceivedReferralPacketAt = omniReceivedReferralPacketAt;
    this.initialEvalConsentSignedAt = initialEvalConsentSignedAt;
    this.initialEvalConsentSentToDOEAt = initialEvalConsentSentToDOEAt;
    this.assessments =
      assessments && assessments.map((item) => new Assessment(item));
    this.summaryCreatedAt = summaryCreatedAt;
    this.summarySentToDOEAt = summarySentToDOEAt;
    this.summarySentToParentAt = summarySentToParentAt;
    this.summaryReviewedWithParentAt = summaryReviewedWithParentAt;
    this.summaryFileId = summaryFileId;
    this.summaryFile = summaryFile && new File(summaryFile);
    this.meetingScheduledAt = meetingScheduledAt;
    this.parentConfirmedMeetingAt = parentConfirmedMeetingAt;
    this.schoolConfirmedMeetingAt = schoolConfirmedMeetingAt;
    this.meetingRepId = meetingRepId;
    this.meetingRep = meetingRep && new Provider(meetingRep);
    this.meetingDate = meetingDate;
    this.meetingStartTime = meetingStartTime;
    this.meetingPhoneNumber = meetingPhoneNumber;
    this.isEligibleForServices = isEligibleForServices;
    this.approvedMandates =
      approvedMandates && approvedMandates.map((item) => new Mandate(item));
    this.notes = notes && notes.map((item) => new Note(item));
    this.documents = documents && documents.map((item) => new Document(item));
    this.terminatedAt = terminatedAt;
  }

  get status() {
    if (Boolean(this.terminatedAt)) {
      return EvaluationStatus.TERMINATED;
    } else if (Boolean(this.completedAt)) {
      return EvaluationStatus.COMPLETE;
    } else if (Boolean(this.summarySentToDOEAt)) {
      return EvaluationStatus.MEETING;
    } else if (Boolean(this.initialEvalConsentSentToDOEAt)) {
      return EvaluationStatus.ASSESSMENTS;
    } else {
      return EvaluationStatus.REFERRAL;
    }
  }

  get formattedTerminatedAt() {
    return formatDate(this.terminatedAt);
  }
}
