<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip top v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn
              fab
              icon
              @click="dialog = true"
              color="primary"
              :disabled="Boolean(evaluation.terminatedAt)"
              ><v-icon>mdi-close-octagon-outline</v-icon></v-btn
            >
          </span>
        </template>
        <span> Terminate Case</span>
      </v-tooltip>
    </template>

    <v-card class="primary--text">
      <v-card-title> Terminate Case? </v-card-title>
      <v-card-text>
        <label>Reason / Comments:</label>
        <v-textarea v-model="comments" auto-grow rows="2" outlined></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark text @click="dialog = false">Cancel</v-btn>
        <v-btn color="primary" dark outlined @click="terminate">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      comments: "",
    };
  },
  computed: {
    evaluation: {
      get() {
        return this.$store.state.evaluationsModule.selectedEval;
      },
      set(newVal) {
        return this.$store.dispatch("evaluationsModule/updateSelectedEval", {
          evaluation: newVal,
        });
      },
    },
    subjectId() {
      return this.$store.state.optionsModule.noteSubjects.find(
        (item) => item.label === "Terminated"
      ).subjectId;
    },
  },
  methods: {
    async terminate() {
      this.evaluation.terminatedAt = new Date();
      this.loading = true;
      let success = await this.$store.dispatch(
        "evaluationsModule/saveSelectedEvaluation"
      );
      if (success) {
        success = await this.$store.dispatch("evaluationsModule/saveNote", {
          evaluationId: this.evaluation?.evaluationId,
          noteText: "Case Terminated: " + this.comments,
          subjectId: this.subjectId,
        });
      }
      this.loading = false;

      if (!success) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error saving changes.");
        this.$store.commit("uxModule/setShowSnackbar", true);
      }

      this.dialog = false;
    },
  },
};
</script>
