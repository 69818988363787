import Home from "../components/HomePage/home";
import EvaluationsHome from "../components/Evaluations/evaluations-home";
import Evaluations from "../components/Evaluations/evaluations";
import EvaluationDetails from "../components/Evaluations/evaluation-details";
import Callback from "../components/Security/callback";
import Error from "../components/Security/error";
import store from "../store/index";

export const routes = [
  {
    name: "Home",
    path: "/",
    icon: "home",
    title: "Home",
    component: Home,
    onNavBar: true,
    onHomePage: false,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/evaluations",
    icon: "clipboard-list",
    title: "Evaluations Tracker",
    component: EvaluationsHome,
    onNavBar: true,
    onHomePage: true,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "Evaluations",
        path: "",
        icon: "list",
        title: "View Cases",
        component: Evaluations,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: "Evaluation Details",
        path: "/evaluations/details",
        component: EvaluationDetails,
        props: true,
        meta: {
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          if (!store.state.evaluationsModule.selectedEval)
            next({ name: "Evaluations" });
          else next();
        },
      },
    ],
  },
  {
    name: "Error",
    path: "/error",
    title: "Error",
    component: Error,
    onNavBar: false,
    onHomePage: false,
  },
  {
    name: "Callback",
    path: "/callback",
    title: "Callback",
    component: Callback,
    onNavBar: false,
    onHomePage: false,
  },
];
