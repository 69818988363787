<template>
  <div>
    <div v-if="childrenOnNavBar">
      <menu-item-group :menuItem="menuItem"></menu-item-group>
    </div>
    <div v-else>
      <menu-item-single :menuItem="menuItem"></menu-item-single>
    </div>
  </div>
</template>

<script>
import MenuItemGroup from "./menu-item-group";
import MenuItemSingle from "./menu-item-single";
export default {
  props: {
    menuItem: Object,
  },
  components: {
    menuItemGroup: MenuItemGroup,
    menuItemSingle: MenuItemSingle,
  },
  computed: {
    childrenOnNavBar() {
      return (
        this.menuItem?.children &&
        this.menuItem?.children.some((item) => item.onNavBar)
      );
    },
  },
};
</script>
