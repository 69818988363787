import { formatPhone } from "../utils/format-utils";

export default class DistrictAdmin {
  districtAdminId?: number | null;
  legacyProviderId?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  streetAddress?: string | null;
  streetAddressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  phone?: string | null;
  emailAddress?: string | null;
  district?: number | null;

  public constructor(params: DistrictAdmin = {} as DistrictAdmin) {
    let {
      districtAdminId = 0,
      legacyProviderId = "",
      title = "",
      firstName = "",
      lastName = "",
      streetAddress = "",
      streetAddressLine2 = "",
      city = "",
      state = "",
      zipCode = "",
      phone = "",
      emailAddress = "",
      district = 0,
    } = params;
    this.districtAdminId = districtAdminId;
    this.legacyProviderId = legacyProviderId;
    this.title = title;
    this.firstName = firstName;
    this.lastName = lastName;
    this.streetAddress = streetAddress;
    this.streetAddressLine2 = streetAddressLine2;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.phone = phone && formatPhone(phone);
    this.emailAddress = emailAddress;
    this.district = district;
  }

  get name() {
    if (this.firstName && this.lastName) {
      if (this.title)
        return this.title + " " + this.firstName + " " + this.lastName;
      else return this.firstName + " " + this.lastName;
    }
  }
}
