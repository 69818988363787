<template>
  <v-data-table
    :headers="headers"
    :items="evaluations"
    @click:row="(item) => goToEvaluationDetails(item)"
    style="cursor: pointer"
  >
    <template v-slot:[`item.status`]="{ item }">
      <v-chip color="primary" outlined small label>{{ item.status }}</v-chip>
    </template>
    <template v-slot:[`item.districtAdmin`]="{ item }">
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">{{ item.districtAdmin?.name }}</span>
        </template>
        <district-admin-details
          v-if="item.districtAdmin"
          :districtAdmin="item.districtAdmin"
        ></district-admin-details>
      </v-tooltip>
    </template>
    <template v-slot:[`item.releaseConsentSignedAt`]="{ item }">
      <v-simple-checkbox
        :value="Boolean(item.releaseConsentSignedAt)"
        disabled
      ></v-simple-checkbox>
    </template>
  </v-data-table>
</template>
<script>
import DistrictAdminDetails from "./district-admin-details.vue";
export default {
  props: ["evaluations", "noEdit"],
  components: {
    DistrictAdminDetails,
  },
  data() {
    return {
      headers: [
        { text: "Client", value: "clientName" },
        { text: "DOB", value: "clientDob" },
        { text: "OSIS", value: "clientOsis" },
        { text: "Status", value: "status", align: "center" },
        { text: "Current School", value: "schoolName" },
        { text: "District", value: "clientDistrict" },
        { text: "District Admin", value: "districtAdmin" },
        {
          text: "Consent to Release Info",
          value: "releaseConsentSignedAt",
          align: "center",
        },
        {
          text: "Referral Date",
          value: "formattedReferralDate",
        },
        {
          text: "Consent For Initial Evaluation",
          value: "formattedInitialEvalConsentSentToDOEAt",
        },
        {
          text: "Meeting Date",
          value: "formattedMeetingDate",
        },
        { text: "Meeting Rep", value: "meetingRepName" },
      ],
      expanded: [],
    };
  },
  methods: {
    async goToEvaluationDetails(item) {
      this.$store.commit("uxModule/setShowLoader", true);
      await this.$store.dispatch("evaluationsModule/loadEvaluation", item);

      this.$router.push({
        name: "Evaluation Details",
        params: { noEdit: this.noEdit },
      });
      this.$store.commit("uxModule/setShowLoader", false);
    },
  },
};
</script>
