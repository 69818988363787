<template>
  <v-data-table
    :headers="_headers"
    :items="assessments"
    no-data-text="No assessments available to display"
    dense
    disable-pagination
    hide-default-footer
  >
    <template v-slot:[`item.response`]="{ item }">
      <v-tooltip v-if="item.providerRespondedAt" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-if="item.providerAccepted" v-bind="attrs" v-on="on"
            >mdi-check-circle</v-icon
          >
          <v-icon v-if="item.providerDeclined" v-bind="attrs" v-on="on"
            >mdi-alpha-x-circle</v-icon
          >
        </template>
        <span v-if="item.providerAccepted" class="font-italic"
          >Accepted {{ formatDate(item.providerRespondedAt) }}</span
        >
        <span v-if="item.providerDeclined" class="font-italic"
          >Declined {{ formatDate(item.providerRespondedAt) }}</span
        >
      </v-tooltip>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <assessment-modal
        v-if="!noEdit"
        :assessmentInput="item"
      ></assessment-modal>
      <delete-assessment-modal
        v-if="!noEdit"
        :assessment="item"
      ></delete-assessment-modal>
      <upload-file-action
        v-if="!noEdit"
        :title="`${item.serviceName} Assessment`"
        :fileId="item.fileId"
        @fileUploaded="updateAssessmentWithFile(item, $event)"
      ></upload-file-action>
      <preview-file-action
        :fileId="item.fileId"
        :file="item.file"
        :disabled="noEdit && !Boolean(summarySentToParentAt)"
      ></preview-file-action>
      <download-file-action
        :fileId="item.fileId"
        :filename="item.filename"
        :disabled="noEdit && !Boolean(summarySentToParentAt)"
      ></download-file-action>
    </template>
  </v-data-table>
</template>
<script>
import { formatDate } from "../../utils/format-utils";
import AssessmentModal from "./assessment-modal.vue";
import DeleteAssessmentModal from "./delete-assessment-modal.vue";
import UploadFileAction from "../Shared/upload-file-action.vue";
import PreviewFileAction from "../Shared/preview-file-action.vue";
import DownloadFileAction from "../Shared/download-file-action.vue";
export default {
  components: {
    AssessmentModal,
    DeleteAssessmentModal,
    UploadFileAction,
    PreviewFileAction,
    DownloadFileAction,
  },
  props: ["assessments", "noEdit", "summarySentToParentAt"],
  data() {
    return {
      headers: [
        { text: "Service", value: "serviceName" },
        { text: "Rationale", value: "rationaleState" },
        { text: "Provider", value: "providerName" },
        {
          text: "Assigned On",
          value: "formattedAssignedOn",
          hide: this.noEdit,
        },
        { text: "Response", value: "response", align: "center" },
        { text: "Assessment Date", value: "formattedAssessmentDate" },
        { text: "Report Received On", value: "formattedReportSubmittedAt" },
        { text: "Actions", value: "actions", soratable: false },
      ],
    };
  },
  computed: {
    _headers() {
      return this.headers.filter((x) => !x.hide);
    },
    evaluationId() {
      return this.$store.state.evaluationsModule.selectedEval?.evaluationId;
    },
  },
  methods: {
    formatDate,
    async updateAssessmentWithFile(item, event) {
      let success = await this.$store.dispatch(
        "evaluationsModule/updateAssessment",
        { ...item, evaluationId: this.evaluationId, fileId: event.fileId }
      );
      if (!success) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error uploading file");
        this.$store.commit("uxModule/setShowSnackbar", true);
      }
    },
  },
};
</script>
