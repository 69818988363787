<template>
  <v-card flat>
    <validation-observer v-slot="{ invalid }">
      <v-card-text>
        <v-stepper v-model="e1" non-linear vertical>
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1" editable>
              Referral
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2" editable>
              Assessments
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :complete="e1 > 3"
              step="3"
              :editable="canScheduleMeeting"
              >Meeting</v-stepper-step
            >
          </v-stepper-header>
          <v-toolbar flat>
            <v-col cols="4">
              <notes-modal :noEdit="noEdit"></notes-modal>
              <documents-modal v-if="!noEdit"></documents-modal>
              <terminate-case-modal
                v-if="!noEdit && !isTerminated"
              ></terminate-case-modal>
              <restore-case-modal
                v-if="!noEdit && isTerminated"
              ></restore-case-modal>
            </v-col>
            <v-col cols="4">
              <span v-if="isTerminated" class="error--text text-subtitle-2"
                >Case terminated on {{ evaluation.formattedTerminatedAt }}. See
                Notes for more details.</span
              >
            </v-col>
          </v-toolbar>
          <v-stepper-items>
            <v-stepper-content step="1">
              <referral-step :noEdit="noEdit"></referral-step>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-1">
              <assessments-step :noEdit="noEdit"></assessments-step>
            </v-stepper-content>
            <v-stepper-content step="3" class="pt-0">
              <meeting-step :noEdit="noEdit"></meeting-step
            ></v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :dark="!loading"
          :disabled="loading"
          plain
          @click="cancel"
          >Cancel</v-btn
        >
        <v-btn
          color="primary"
          text
          :dark="!noEdit && !invalid && !loading"
          :disabled="noEdit || invalid || loading"
          :loading="loading"
          @click="save"
          >Save Changes</v-btn
        ></v-card-actions
      >
    </validation-observer>
  </v-card>
</template>
<script>
import ReferralStep from "./referral-step.vue";
import AssessmentsStep from "./assessments-step.vue";
import MeetingStep from "./meeting-step.vue";
import NotesModal from "./notes-modal.vue";
import DocumentsModal from "./documents-modal.vue";
import TerminateCaseModal from "./terminate-case-modal.vue";
import RestoreCaseModal from "./restore-case-modal.vue";
import { formatDate } from "../../utils/format-utils";
export default {
  props: ["noEdit"],
  components: {
    ReferralStep,
    AssessmentsStep,
    MeetingStep,
    NotesModal,
    DocumentsModal,
    TerminateCaseModal,
    RestoreCaseModal,
  },
  data() {
    return {
      e1: 1,
      loading: false,
    };
  },
  mounted() {
    switch (this.evaluation?.status) {
      case "Assessments":
        this.e1 = 2;
        break;
      case "Meeting":
      case "Complete":
        this.e1 = 3;
        break;
      default:
        this.e1 = 1;
    }
  },
  computed: {
    evaluation() {
      return this.$store.state.evaluationsModule.selectedEval;
    },
    canScheduleMeeting() {
      return this.evaluation.summarySentToDOEAt != null;
    },
    isTerminated() {
      return Boolean(this.evaluation.terminatedAt);
    },
  },
  methods: {
    formatDate,
    cancel() {
      this.$router.push({ name: "Evaluations" });
    },
    async save() {
      this.loading = true;
      let success = await this.$store.dispatch(
        "evaluationsModule/saveSelectedEvaluation"
      );
      this.loading = false;

      if (!success) {
        this.$store.commit("uxModule/setSnackbarMsg", "Error saving changes");
        this.$store.commit("uxModule/setShowSnackbar", true);
      } else {
        this.$router.push({ name: "Evaluations" });
      }
    },
  },
};
</script>
