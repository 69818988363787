import { formatDate } from "../utils/format-utils";
import Client from "./Client";
import Program from "./Program";
import Service from "./Service";
import store from "../store/index";

export default class Mandate {
  mandateId?: number;
  legacyIEPPlanId?: number | null;
  clientId?: number | null;
  client?: Client | null;
  programId?: number | null;
  program?: Program | null;
  serviceId?: number | null;
  service?: Service | null;
  groupSize?: number | null;
  frequency?: number | null;
  durationMinutes?: number | null;
  locationId?: number | null;
  location?: string | null;
  languageId?: number | null;
  language?: string | null;
  IEPPlanDate?: Date | null;
  authStartDate?: Date | null;
  authEndDate?: Date | null;
  startDate?: Date | null;
  endDate?: Date | null;
  isTwelveMonth?: Boolean | null;

  public constructor(params: Mandate = {} as Mandate) {
    let {
      mandateId = 0,
      legacyIEPPlanId = null,
      clientId = null,
      client = null,
      programId = null,
      program = null,
      serviceId = null,
      service = null,
      groupSize = 1,
      frequency = 0,
      durationMinutes = 0,
      locationId = null,
      location = "",
      languageId = null,
      language = "",
      IEPPlanDate = null,
      authStartDate = null,
      authEndDate = null,
      startDate = null,
      endDate = null,
      isTwelveMonth = false,
    } = params;
    this.mandateId = mandateId;
    this.legacyIEPPlanId = legacyIEPPlanId;
    this.clientId = clientId;
    this.client = client && new Client(client);
    this.programId = programId;
    this.program = program && new Program(program);
    this.serviceId = serviceId;
    this.service = service && new Service(service);
    this.groupSize = groupSize;
    this.frequency = frequency;
    this.durationMinutes = durationMinutes;
    this.locationId = locationId;
    this.location = location;
    this.languageId = languageId;
    this.language = language;
    this.IEPPlanDate = IEPPlanDate;
    this.authStartDate = authStartDate;
    this.authEndDate = authEndDate;
    this.startDate = startDate;
    this.endDate = endDate;
    this.isTwelveMonth = isTwelveMonth;
  }

  get programName() {
    return (
      this.program?.name ||
      store.getters["optionsModule/program"](this.programId)
    );
  }

  get serviceName() {
    return (
      this.service?.name ||
      store.getters["optionsModule/service"](this.serviceId)
    );
  }

  get formattedAuthStartDate() {
    return formatDate(this.authStartDate);
  }

  get formattedAuthEndDate() {
    return formatDate(this.authEndDate);
  }

  get formattedStartDate() {
    return formatDate(this.startDate);
  }

  get formattedEndDate() {
    return formatDate(this.endDate);
  }

  get tenOrTwelveMonth() {
    return this.isTwelveMonth ? "12" : "10";
  }
}
