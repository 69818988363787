<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        tile
        outlined
        depressed
        color="primary"
        dark
        v-on="on"
        v-bind="attrs"
        @click="dialog = true"
        class="mx-2"
        ><v-icon small color="primary">mdi-plus</v-icon>New Case</v-btn
      >
    </template>

    <v-card>
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-card-title>New Case</v-card-title>
        <v-card-text class="text--center">
          <validation-provider
            name="client"
            :rules="validations.client"
            v-slot="{ errors }"
          >
            <v-autocomplete
              v-model="clientId"
              :items="clients"
              clearable
              label="Select the client"
              :item-text="
                (item) =>
                  `${item.lastName}, ${item.firstName} (${formatDate(
                    item.dateOfBirth
                  )})`
              "
              item-value="clientId"
              :loading="loading"
              dense
              class="my-3"
              :error-messages="errors"
            ></v-autocomplete>
          </validation-provider>
          <v-select
            v-model="programId"
            :items="programs"
            :item-text="(item) => `${item.code} - ${item.name}`"
            item-value="programId"
            label="Select the program"
            class="my-3"
          ></v-select>
          <v-select
            v-model="type"
            :items="types"
            label="Select the type"
            class="my-3"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="clear"> Cancel </v-btn>
          <v-btn
            color="primary"
            text
            :dark="!invalid"
            :disabled="invalid"
            @click="create"
          >
            Create
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDate } from "../../utils/format-utils";
export default {
  props: ["loading"],
  data() {
    return {
      dialog: false,
      clientId: "",
      programId: 1,
      type: "Initial",
    };
  },
  computed: {
    validations() {
      return {
        client: {
          required: true,
          distinct: [this.activeClientIds],
        },
      };
    },
    activeClientIds() {
      return this.$store.getters["evaluationsModule/getActiveClientIds"];
    },
    clients() {
      return this.$store.state.optionsModule.clients;
    },
    programs() {
      return this.$store.state.optionsModule.programs;
    },
    types() {
      return this.$store.state.optionsModule.evaluationTypes;
    },
  },
  methods: {
    formatDate,
    async create() {
      this.dialog = false;
      this.$store.commit("uxModule/setShowLoader", true);
      let success = await this.$store.dispatch(
        "evaluationsModule/createEvaluation",
        {
          clientId: this.clientId,
          programId: this.programId,
          evaluationType: this.type,
        }
      );
      this.$store.commit("uxModule/setShowLoader", false);
      if (!success) {
        this.$store.commit(
          "uxModule/setSnackbarMsg",
          "Error creating new case"
        );
        this.$store.commit("uxModule/setShowSnackbar", true);
      } else {
        this.$router.push({ name: "Evaluation Details" });
      }
      this.clear();
    },
    clear() {
      this.$refs.form.reset();
      this.clientId = "";
      this.programId = 1;
      this.type = "Initial";
      this.dialog = false;
    },
  },
};
</script>
