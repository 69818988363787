import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

Vue.config.devtools = true;

import uxModule from "./Modules/uxModule";
import sharedModule from "./Modules/sharedModule";
import securityModule from "./Modules/securityModule";
import evaluationsModule from "./Modules/evaluationsModule";
import optionsModule from "./Modules/optionsModule";
import filesModule from "./Modules/filesModule";
const state = {};

const mutations = {};

const actions = {};

const store = new Vuex.Store({
  modules: {
    uxModule: uxModule,
    sharedModule: sharedModule,
    securityModule: securityModule,
    evaluationsModule: evaluationsModule,
    optionsModule: optionsModule,
    filesModule: filesModule,
  },

  state,
  mutations,
  actions,
  //plugins: [
  //  createPersistedState({
  //    storage: window.sessionStorage,
  //  }),
  //],
});

export default store;
